<template>
    <form ref="form" action="" :class="formClass" :novalidate="true">
        <slot></slot>
        <slot name="actionButtons" v-if="showActionButtons && !isReadOnly">
            <div class="form-group d-flex justify-content-end mt-4">
                <button
                    class="btn btn-light lh-1"
                    type="button"
                    style="margin-right: 0.75rem"
                    :disabled="form.$busy ? true : false"
                    @click="this.form.$reset()"
                    v-if="showResetButton"
                >
                    <span class="">
                        <vue-feather type="x" size="1rem" />
                        RESET
                    </span>
                </button>
                <button class="btn btn-primary" type="button" @click="submitForm" :disabled="form.$busy ? true : false">
                    <span>
                        <vue-feather v-if="form.$busy" type="loader" size="1rem" animation="spin" />
                        <vue-feather v-else type="save" size="1rem" />

                        {{ form.$busy ? 'Saving' : buttonSubmitLabel }}
                    </span>
                </button>
            </div>
        </slot>
    </form>
</template>

<script>
import _ from 'lodash';
import Form from '@/utils/form';
/**
 * Event to be emitted.
 *
 * beforeSubmit - this event will br triggered once the submitForm() is executed, or lets say
 * when the submit button is triggered. the purpose of this is to execute a callback function to the child component
 * before submiting the form
 *
 * onSaveForm: - Once the vadidation process is completed and successful, the form will emit this event to pass the field
 * values avaiable in this form
 *
 * Todo: Add event for validation failure if needed.
 */
export default {
    name: 'Form2',
    props: {
        formClass: {
            type: String,
            default: '',
        },

        showActionButtons: {
            type: Boolean,
            default: true,
        },

        showResetButton: {
            type: Boolean,
            default: true,
        },

        buttonSubmitLabel: {
            type: String,
            default: 'Submit',
        },

        isReadOnly: {
            type: Boolean,
            default: false,
        },

        formRefs: {
            type: Object,
            default: () => new Form(),
        },
    },

    provide() {
        return {
            register: (ref) => this.registerFeild(ref),
            unRegisterField: (ref) => this.unRegisterField(ref),
            isReadOnlyField: this.isReadOnly,
        };
    },

    data() {
        return {
            form: this.formRefs,
            formData: {},
            formErrors: {},
            registeredField: [],
        };
    },

    methods: {
        registerFeild(ref) {
            // Register the child component when mounted
            this.registeredField.push(ref);
        },

        unRegisterField(ref) {
            // remove the child component when its unmounted
            const refIndex = this.registeredField.findIndex((component) => ref === component);

            if (refIndex !== -1) {
                this.registeredField.splice(refIndex, 1);
            }
        },

        submitForm() {
            this.$emit('beforeSubmit');

            const isValidationPassed = this.validateFields();

            if (isValidationPassed) {
                this.registeredField.forEach((field) => {
                    field.isValidated = false;
                });
                this.$emit('onSaveForm', this.formData);
            }
        },

        validateFields() {
            this.formErrors = {};
            this.formData = {};

            this.registeredField.forEach((field) => {
                const VALIDATION_RESULT = field.validate();
                if (VALIDATION_RESULT !== true) {
                    this.formErrors[field.name] = VALIDATION_RESULT;
                } else {
                    this.formData[field.name] = field.localValue ?? field.modelValue;
                }
            });

            return _.isEmpty(this.formErrors);
        },

        // additional support for set loading when the child component is not using Form Util
        loading(isLoading = true) {
            this.form.$busy = isLoading;
        },
    },
};
</script>
