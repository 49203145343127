<template>
    <div class="form-group">
        <slot name="label">
            <div v-if="!isEmptyLabel(label)">
                <label class="text-muted fw-bold" :for="fieldId" v-html="label + ':'"></label>
                <span class="text-danger" v-if="required && !isReadOnly">*</span>
            </div>
        </slot>
        <input
            :id="fieldId"
            :placeholder="placeholder"
            :required="required"
            :type="type"
            :name="name"
            ref="input"
            v-bind:class="strClass"
            :disabled="disabled"
            :value="modelValue"
            @input="handleUpdate"
            @keyup="validate"
            v-if="!isReadOnly"
        />
        <div v-else>
            <p>
                {{ modelValue ?? 'n/a' }}
            </p>
        </div>
        <div class="invalid-feedback text-danger" style="margin-top: 0.5rem" v-if="errorMessage !== null">
            <vue-feather type="alert-octagon" size="1rem" />
            {{ errorMessage }}
        </div>
    </div>
</template>

<script>
import FormFieldMixin from '@/components/Form2/mixins/FormFieldMixin';
import _ from 'lodash';

const SUPPORTED_TYPES = ['text', 'email', 'number', 'password', 'time', 'file'];

export default {
    name: 'Form2InputField',

    mixins: [FormFieldMixin],

    inject: ['register', 'unRegisterField', 'isReadOnlyField'],

    props: {
        type: {
            type: String,
            default: 'text',
        },

        minLength: {
            type: Number,
            default: 0,
        },
        maxLength: {
            type: Number,
            default: Infinity,
        },

        disabled: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            localValue: null,
            isValidated: false,
        };
    },

    methods: {
        isEmptyLabel(value) {
            let isEmpty = _.isEmpty(value);

            return isEmpty;
        },

        handleUpdate(event) {
            let value = this.type === 'file' ? event.target.files[0] : event.target.value;
            this.$emit('update:modelValue', event.target.value);
            this.$emit('onChange', value);
            this.localValue = event.target.value;
        },

        isSupportedTypes() {
            return SUPPORTED_TYPES.includes(this.type);
        },

        validate() {
            this.isValidated = false;
            // check if input type is supported this, remember checkbox radio select types, doesnt have like this checking
            if (!this.isSupportedTypes()) {
                this.errorMessage = `Unrecognized INPUT_TYPE "${this.type}"`;
                return this.errorMessage;
            }

            let result = this.basicValidation(
                this.label,
                this.type,
                this.modelValue,
                this.required,
                this.minLength,
                this.maxLength
            );

            if (result === true) {
                result = this.runRule();
            }

            if (result !== true) {
                this.errorMessage = result;
            } else {
                this.errorMessage = null;
            }

            // run the the custom rule callback
            // example use: <component :rule="(value) => value === true ? true  : 'error message'" />

            this.isValidated = true;

            return result;
        },

        basicValidation(label, type, value, required, minLength, maxLength) {
            if (required && (value === null || value === '')) {
                return `${label} is required`;
            } else if (minLength > 0 && value && value.length < minLength) {
                return `${label} should be at least ${minLength} long`;
            } else if (maxLength < Infinity && value && value.length > maxLength) {
                return `${label} shouldn't exceed ${maxLength} long`;
            } else if (type === 'email' && value && !this.isValidEmail(value)) {
                return `${label} is not a valid email`;
            }

            return true;
        },

        isValidEmail(value) {
            return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
        },

        isValidNumber(value) {
            var pattern = /^\d+\.?\d*$/;
            return pattern.test(value);
        },
    },

    computed: {
        isReadOnly() {
            let readOnly = false;

            if (typeof this.isReadOnlyField !== 'undefined') {
                readOnly = this.isReadOnlyField;
            }

            return readOnly;
        },
    },

    mounted() {
        this.register(this);
    },

    unmounted() {
        this.unRegisterField(this);
    },
};
</script>
