import PerformanceOfWorkType from '@/models/PerformanceOfWorkType';
import { each, find } from 'lodash';

export default {
    namespaced: true,
    state: {
        list: [],
        listMeta: {
            current_page: 0,
            last_page: 1,
        },
    },

    mutations: {
        setPerformanceOfWorkTypeList(state, performanceOfWorkType) {
            each(performanceOfWorkType, (status) => {
                const exist = find(state.list, { id: status.id });
                if (!exist) {
                    state.list.push(new PerformanceOfWorkType(status));
                }
            });
        },

        clearPerformanceOfWorkTypeList(state) {
            state.list = [];
        },

        clearPerformanceOfWorkTypeDetails(state) {
            state.performanceOfWorkTypeDetails = null;
        },

        setPerformanceOfWorkTypeListMeta(state, meta) {
            state.listMeta = meta;
        },

        setPerformanceOfWorkTypeDetails(state, status) {
            if (status instanceof PerformanceOfWorkType) {
                state.performanceOfWorkTypeDetails = status;
            } else {
                state.performanceOfWorkTypeDetails = new PerformanceOfWorkType(status);
            }
        },
    },

    actions: {
        async getPerformanceOfWorkType({ commit }, params) {
            const query = PerformanceOfWorkType.page(params.page || 1).orderBy(params.sort);

            if (params.search) {
                query.where('search', params.search);
            }

            const res = await query.params({ type: params.type }).get();

            commit('setPerformanceOfWorkTypeList', res.data);
            commit('setPerformanceOfWorkTypeListMeta', res.meta);
        },

        async getPerformanceOfWorkTypeDetails({ commit }, id) {
            const { data } = await this.$api.get(`/performance-of-work-type/${id}`);

            commit('setPerformanceOfWorkTypeDetails', data.data);
        },

        async storePerformanceOfWorkType({ commit }, formData) {
            const { data } = await this.$api.post(`/performance-of-work-type`, formData);

            commit('setPerformanceOfWorkTypeDetails', data.data);
        },

        async updatePerformanceOfWorkType({ commit }, formData) {
            const { data } = await this.$api.post(`/performance-of-work-type/${formData.id}`, formData.data);

            commit('setPerformanceOfWorkTypeDetails', data.data);
        },

        async updateActivePerformanceOfWorkTypeTranches({ commit }, id) {
            const { data } = await this.$api.post(`/update-active-salary-grade-tranch/${id}`);

            commit('setPerformanceOfWorkTypeDetails', data.data);
        },

        async deletePerformanceOfWorkType({ commit }, id) {
            await this.$api.delete(`/performance-of-work-type/${id}`);
        },
    },
};
