import EmployeeSchedule from '@/models/EmployeeSchedule';
import { each, find } from 'lodash';

export default {
    namespaced: true,
    state: {
        list: [],
        listMeta: {
            current_page: 0,
            last_page: 1,
        },
    },

    mutations: {
        setEmployeeScheduleList(state, employeeschedule) {
            each(employeeschedule, (status) => {
                const exist = find(state.list, { id: status.id });
                if (!exist) {
                    state.list.push(new EmployeeSchedule(status));
                }
            });
        },

        clearEmployeeScheduleList(state) {
            state.list = [];
        },

        clearEmployeeScheduleDetails(state) {
            state.employeeScheduleDetails = null;
        },

        setEmployeeScheduleListMeta(state, meta) {
            state.listMeta = meta;
        },

        setEmployeeCalendarDetails(state, data) {
            state.employeeCalendarDetails = data;
        },

        setEmployeeScheduleDetails(state, status) {
            if (status instanceof EmployeeSchedule) {
                state.employeeScheduleDetails = status;
            } else {
                state.employeeScheduleDetails = new EmployeeSchedule(status);
            }
        },
    },

    actions: {
        async getEmployeeSchedule({ commit }, params) {
            const query = EmployeeSchedule.page(params.page || 1).orderBy(params.sort);

            if (params.search) {
                query.where('search', params.search);
            }

            const res = await query.params({ limit: 20, type: params.type }).get();

            commit('setEmployeeScheduleList', res.data);
            commit('setEmployeeScheduleListMeta', res.meta);
        },

        async getEmployeeScheduleDetails({ commit }, id) {
            const { data } = await this.$api.get(`/employee-schedule/${id}`);

            commit('setEmployeeScheduleDetails', data.data);
        },

        async getEmployeeScheduleCalendarDetails({ commit }, calendar) {
            const { data } = await this.$api.get(
                `/employee-schedule/get-employee-calendar-schedule/${calendar.id}/${calendar.year}/${calendar.month}`
            );

            commit('setEmployeeCalendarDetails', data);
        },

        async storeEmployeeSchedule({ commit }, formData) {
            const { data } = await this.$api.post(`/employee-schedule`, formData);

            commit('setEmployeeScheduleDetails', data.data);
        },

        async updateEmployeeSchedule({ commit }, formData) {
            const { data } = await this.$api.post(`/employee-schedule/${formData.id}`, formData.data);

            commit('setEmployeeScheduleDetails', data.data);
        },

        async deleteEmployeeSchedule({ commit }, id) {
            await this.$api.delete(`/employee-schedule/${id}`);
        },
    },
};
