<template>
    <div>
        <modal ref="fileUploadModal" :show-button="false">
            <h5 class="text-center">{{ title }}</h5>
            <input
                class="form-control mb-3"
                type="file"
                @change="handleFileChange"
                ref="fileInput"
                :multiple="isMultiple"
            />
            <button
                class="btn btn-primary w-100"
                @click="
                    () => {
                        this.$emit('onSubmit');
                    }
                "
            >
                <strong v-if="isLoading">Please wait...</strong>
                <strong v-else>{{ buttonText }}</strong>
            </button>
        </modal>
    </div>
</template>
<script>
import { each } from 'lodash';

export default {
    name: 'FileUpload',

    data() {
        return {
            preData: null,
            isLoading: false,
            formData: new FormData(),
        };
    },

    props: {
        title: {
            type: String,
            default: 'Generic Uploader',
        },

        isMultiple: {
            type: Boolean,
            default: true,
        },

        buttonText: {
            type: String,
            default: 'Upload',
        },
    },

    methods: {
        showUploader() {
            this.$refs.fileUploadModal.showModal();
        },

        hideUploader() {
            // Hide the modal
            this.$refs.fileUploadModal.closeModal();
        },

        setFormData(data) {
            each(data, (value, key) => {
                this.formData.append(key, value);
            });
        },

        handleFileChange(event) {
            // Handle file change event
            const uploadData = event.target.files;
            const key = this.isMultiple ? 'files[]' : 'file';

            if (this.isMultiple) {
                for (let i = 0; i < uploadData.length; i++) {
                    this.formData.set(key, uploadData[i]);
                }
            } else {
                this.formData.set(key, uploadData[0]);
            }
        },

        proceed(url, method = 'post', sucessCallback = () => {}, finallyCallback = () => {}, errorCallback = () => {}) {
            if (method === 'put') {
                this.formData.append('_method', 'PUT');
            }

            this.isLoading = true;

            this.$api
                .post(url, this.formData)
                .then(() => {
                    sucessCallback();
                    this.hideUploader();
                })
                .catch(() => {
                    errorCallback();
                })
                .finally(() => {
                    finallyCallback();
                    this.isLoading = false;
                });
        },
    },
};
</script>
