import LearningAndDevelopment from '@/models/LearningAndDevelopment';
import { each, find } from 'lodash';

export default {
    namespaced: true,
    state: {
        list: [],
        listMeta: {
            current_page: 0,
            last_page: 1,
        },
    },

    mutations: {
        setLearningAndDevelopmentList(state, learningAndDevelopment) {
            each(learningAndDevelopment, (status) => {
                const exist = find(state.list, { id: status.id });
                if (!exist) {
                    state.list.push(new LearningAndDevelopment(status));
                }
            });
        },

        clearLearningAndDevelopmentList(state) {
            state.list = [];
        },

        clearLearningAndDevelopmentDetails(state) {
            state.learningAndDevelopmentDetails = null;
        },

        setLearningAndDevelopmentListMeta(state, meta) {
            state.listMeta = meta;
        },

        setLearningAndDevelopmentDetails(state, status) {
            if (status instanceof LearningAndDevelopment) {
                state.learningAndDevelopmentDetails = status;
            } else {
                state.learningAndDevelopmentDetails = new LearningAndDevelopment(status);
            }
        },
    },

    actions: {
        async getLearningAndDevelopment({ commit }, params) {
            const query = LearningAndDevelopment.page(params.page || 1).orderBy(params.sort);

            if (params.search) {
                query.where('search', params.search);
            }

            const res = await query.params({ limit: 20, type: params.type }).get();

            commit('setLearningAndDevelopmentList', res.data);
            commit('setLearningAndDevelopmentListMeta', res.meta);
        },

        async getLearningAndDevelopmentDetails({ commit }, id) {
            const { data } = await this.$api.get(`/learning-and-development/${id}`);

            commit('setLearningAndDevelopmentDetails', data.data);
        },

        async storeLearningAndDevelopment({ commit }, formData) {
            let config = {
                header: {
                    'Content-Type': 'application/json;charset=UTF-8',
                },
            };

            const { data } = await this.$api.post(`/learning-and-development`, formData, config);

            commit('setLearningAndDevelopmentDetails', data.data);
        },

        async updateLearningAndDevelopment({ commit }, formData) {
            const { data } = await this.$api.post(`/learning-and-development/${formData.id}`, formData.data);

            commit('setLearningAndDevelopmentDetails', data.data);
        },

        async deleteLearningAndDevelopment({ commit }, id) {
            await this.$api.delete(`/learning-and-development/${id}`);
        },
    },
};
