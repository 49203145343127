import EmployeeStatus from '@/models/EmployeeStatus';
import { each, find } from 'lodash';

export default {
    namespaced: true,
    state: {
        list: [],
        listMeta: {
            current_page: 0,
            last_page: 1,
        },
    },

    mutations: {
        setEmployeeStatusList(state, employeeStatus) {
            each(employeeStatus, (status) => {
                const exist = find(state.list, { id: status.id });
                if (!exist) {
                    state.list.push(new EmployeeStatus(status));
                }
            });
        },

        clearEmployeeStatusList(state) {
            state.list = [];
        },

        clearEmployeeStatusDetails(state) {
            state.employeeStatusDetails = null;
        },

        setEmployeeStatusListMeta(state, meta) {
            state.listMeta = meta;
        },

        setEmployeeStatusDetails(state, status) {
            if (status instanceof EmployeeStatus) {
                state.employeeStatusDetails = status;
            } else {
                state.employeeStatusDetails = new EmployeeStatus(status);
            }
        },
    },

    actions: {
        async getEmployeeStatus({ commit }, params) {
            const query = EmployeeStatus.page(params.page || 1).orderBy(params.sort);

            if (params.search) {
                query.where('search', params.search);
            }

            const res = await query.params({ limit: 20, type: params.type }).get();

            commit('setEmployeeStatusList', res.data);
            commit('setEmployeeStatusListMeta', res.meta);
        },

        async getEmployeeStatusDetails({ commit }, id) {
            const { data } = await this.$api.get(`/employee-status/${id}`);

            commit('setEmployeeStatusDetails', data.data);
        },

        async storeEmployeeStatus({ commit }, formData) {
            const { data } = await this.$api.post(`/employee-status`, formData);
            commit('setEmployeeStatusDetails', data.data);
        },

        async updateEmployeeStatus({ commit }, formData) {
            const { data } = await this.$api.post(`/employee-status/${formData.id}`, formData.data);

            commit('setEmployeeStatusDetails', data.data);
        },

        async deleteEmployeeStatus({ commit }, id) {
            await this.$api.delete(`/employee-status/${id}`);
        },
    },
};
