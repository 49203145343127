<template>
    <div class="form-group">
        <label class="fw-bold text-muted" :for="fieldId">{{ label }}</label>
        <span class="text-danger" v-if="required">*</span>
        <select
            :id="fieldId"
            :required="required"
            :name="name"
            v-model="localValue"
            @change="handleChange"
            aria-label=".form-select-sm"
            v-bind:class="strClass"
            v-if="!isReadOnly"
        >
            <option disabled selected="true" :value="null">-- select --</option>
            <option v-for="(item, key) in options" :value="item.value" :key="key">
                {{ item.text }}
            </option>
        </select>
        <div v-else>
            {{ displayValue(localValue) }}
        </div>
        <div class="invalid-feedback text-danger" style="margin-top: 0.5rem" v-if="errorMessage !== null">
            <vue-feather type="alert-octagon" size="1rem" />
            {{ errorMessage }}
        </div>
    </div>
</template>

<script>
'use strict';

export default {
    name: 'Form2Select',

    inject: ['register', 'unRegisterField', 'isReadOnlyField'],

    props: {
        required: {
            type: Boolean,
            default: false,
        },

        label: {
            type: String,
            default: '',
        },

        options: {
            type: Array,
            default: () => [],
        },

        inputClass: {
            type: String,
            default: 'form-select',
        },

        name: {
            type: String,
            default: '',
        },

        modelValue: {},

        responseError: {
            type: String,
            default: '',
        },

        isShowDefaultSelected: {
            type: Boolean,
            default: true,
        },
    },

    emits: ['update:modelValue'],

    watch: {
        modelValue(newValue) {
            this.localValue = newValue;
            if (this.localValue) {
                this.validate();
            }
        },
    },

    data() {
        return {
            localValue: this.modelValue,
            isValidated: false,
            errorMessage: null,
        };
    },

    methods: {
        handleChange() {
            this.$emit('update:modelValue', this.localValue);
        },

        validate() {
            this.isValidated = false;

            let result = this.basicValidation();

            if (result !== true) {
                this.errorMessage = result;
            } else {
                this.errorMessage = null;
            }

            this.isValidated = true;

            return result;
        },

        basicValidation() {
            if (this.required && (this.localValue === '' || this.localValue === null)) {
                return `${this.label} is required.`;
            }

            return true;
        },

        displayValue(value) {
            let matchedOption = this.options.filter((item) => item.value === value);
            let result = 'n/a';

            if (matchedOption.length > 0) {
                result = matchedOption[0].text;
            }

            return result;
        },
    },

    computed: {
        strClass() {
            let strClass = this.inputClass;
            if (this.errorMessage !== null) {
                strClass = strClass + ' is-invalid';
            } else if (this.errorMessage === null && this.isValidated && this.modelValue !== null) {
                strClass = strClass + ' is-valid';
            }

            return strClass;
        },

        fieldId() {
            return `${this.name}${Math.random().toString(36).substring(2, 15)}`;
        },

        isReadOnly() {
            let readOnly = false;

            if (typeof this.isReadOnlyField !== 'undefined') {
                readOnly = this.isReadOnlyField;
            }

            return readOnly;
        },
    },

    mounted() {
        this.register(this);
    },

    unmounted() {
        this.unRegisterField(this);
    },
};
</script>
