import Branch from '@/models/Branch';
import { each, find } from 'lodash';

export default {
    namespaced: true,
    state: {
        list: [],
        listMeta: {
            current_page: 0,
            last_page: 1,
        },
    },

    mutations: {
        setBranchList(state, branch) {
            each(branch, (status) => {
                const exist = find(state.list, { id: status.id });
                if (!exist) {
                    state.list.push(new Branch(status));
                }
            });
        },

        clearBranchList(state) {
            state.list = [];
        },

        clearBranchDetails(state) {
            state.branchDetails = null;
        },

        setBranchListMeta(state, meta) {
            state.listMeta = meta;
        },

        setBranchDetails(state, status) {
            if (status instanceof Branch) {
                state.branchDetails = status;
            } else {
                state.branchDetails = new Branch(status);
            }
        },
    },

    actions: {
        async getBranch({ commit }, params) {
            const query = Branch.page(params.page || 1).orderBy(params.sort);

            if (params.search) {
                query.where('search', params.search);
            }

            const res = await query.params({ limit: 20, type: params.type }).get();

            commit('setBranchList', res.data);
            commit('setBranchListMeta', res.meta);
        },

        async getBranchDetails({ commit }, id) {
            const { data } = await this.$api.get(`/branches/${id}`);

            commit('setBranchDetails', data.data);
        },

        async storeBranch({ commit }, formData) {
            const { data } = await this.$api.post(`/branches`, formData);

            commit('setBranchDetails', data.data);
        },

        async updateBranch({ commit }, formData) {
            const { data } = await this.$api.post(`/branches/${formData.id}`, formData.data);

            commit('setBranchDetails', data.data);
        },

        async deleteBranch({ commit }, id) {
            await this.$api.delete(`/branches/${id}`);
        },
    },
};
