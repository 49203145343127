<template>
    <div>
        <div class="form-check">
            <input
                v-bind:class="strClass"
                type="checkbox"
                v-model="model"
                id="fieldId"
                :name="name"
                :disabled="isReadOnly"
            />
            <label class="form-check-label" :for="fieldId">
                {{ label }}
            </label>
        </div>

        <div class="text-danger" v-if="errorMessage !== null" style="font-size: 12px">
            <vue-feather type="alert-octagon" size="1rem" />
            {{ errorMessage }}
        </div>
    </div>
</template>
<script>
export default {
    props: {
        label: { type: String },
        modelValue: {
            type: [Array, Boolean, Number],
        },
        name: {
            type: String,
            default: 'checkbox',
        },

        value: { type: [Boolean, Object] },

        inputClass: {
            type: String,
            default: 'form-check-input',
        },

        required: {
            type: Boolean,
            default: false,
        },

        isStandAlone: {
            type: Boolean,
            default: false,
        },
    },

    inject: ['register', 'unRegisterField', 'isReadOnlyField'],

    data() {
        return {
            localValue: null,
            errorMessage: null,
            isValidated: false,
        };
    },

    watch: {
        modelValue(value) {
            if (value) {
                this.validate();
            }
        },

        responseError(value) {
            if (value !== '' && value !== null) {
                this.errorMessage = value;
            }
        },
    },

    methods: {
        validate() {
            this.isValidated = false;

            let result = this.basicValidation();

            if (result !== true) {
                this.errorMessage = result;
            } else {
                this.errorMessage = null;
            }

            this.isValidated = true;

            return result;
        },

        basicValidation() {
            if (this.required && (this.modelValue === '' || this.modelValue === null)) {
                return `${this.label} is required.`;
            }

            return true;
        },
    },

    computed: {
        fieldId() {
            return `${this.name}${Math.random().toString(36).substring(2, 15)}`;
        },

        model: {
            get() {
                return !!this.modelValue;
            },
            set(value) {
                this.localValue = value;
                this.$emit('update:modelValue', value);
                this.$emit('onChange', value);
            },
        },

        strClass() {
            let strClass = this.inputClass;
            if (this.errorMessage !== null) {
                strClass = strClass + ' is-invalid';
            } else if (this.errorMessage === null && this.isValidated && this.modelValue !== null) {
                strClass = strClass + ' is-valid';
            }

            return strClass;
        },

        isReadOnly() {
            let readOnly = false;

            if (typeof this.isReadOnlyField !== 'undefined') {
                readOnly = this.isReadOnlyField;
            }

            return readOnly;
        },
    },

    mounted() {
        this.localValue = this.modelValue;

        if (!this.isStandAlone) {
            this.register(this);
        }
    },

    unmounted() {
        if (!this.isStandAlone) {
            this.unRegisterField(this);
        }
    },
};
</script>
