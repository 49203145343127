import Calendar from '@/models/Calender';
import { each, find } from 'lodash';

export default {
    namespaced: true,
    state: {
        list: [],
        listMeta: {
            current_page: 0,
            last_page: 1,
        },
    },

    mutations: {
        setCalendarList(state, calendar) {
            each(calendar, (status) => {
                const exist = find(state.list, { id: status.id });
                if (!exist) {
                    state.list.push(new Calendar(status));
                }
            });
        },

        clearCalendarList(state) {
            state.list = [];
        },

        clearCalendarDetails(state) {
            state.calendarDetails = null;
        },

        setCalendarListMeta(state, meta) {
            state.listMeta = meta;
        },

        setCalendarDetails(state, status) {
            if (status instanceof Calendar) {
                state.calendarDetails = status;
            } else {
                state.calendarDetails = new Calendar(status);
            }
        },
    },

    actions: {
        async getCalendar({ commit }, params) {
            const query = Calendar.page(params.page || 1).orderBy(params.sort);

            if (params.search) {
                query.where('search', params.search);
            }

            const res = await query.params({ limit: 20, type: params.type }).get();

            commit('setCalendarList', res.data);
            commit('setCalendarListMeta', res.meta);
        },

        async getCalendarDetails({ commit }, id) {
            const { data } = await this.$api.get(`/holiday/${id}`);

            commit('setCalendarDetails', data.data);
        },

        async storeCalendar({ commit }, formData) {
            const { data } = await this.$api.post(`/holiday`, formData);

            commit('setCalendarDetails', data.data);
        },

        async updateCalendar({ commit }, formData) {
            const { data } = await this.$api.post(`/holiday/${formData.id}`, formData.data);

            commit('setCalendarDetails', data.data);
        },

        async deleteCalendar({ commit }, id) {
            await this.$api.delete(`/holiday/${id}`);
        },
    },
};
