import Governance from '@/models/Governance';
import { each, find } from 'lodash';

export default {
    namespaced: true,
    state: {
        list: [],
        listMeta: {
            current_page: 0,
            last_page: 1,
        },
    },

    mutations: {
        setGovernanceList(state, governance) {
            each(governance, (status) => {
                const exist = find(state.list, { id: status.id });
                if (!exist) {
                    state.list.push(new Governance(status));
                }
            });
        },

        clearGovernanceList(state) {
            state.list = [];
        },

        clearGovernanceDetails(state) {
            state.governanceDetails = null;
        },

        setGovernanceListMeta(state, meta) {
            state.listMeta = meta;
        },

        setGovernanceDetails(state, status) {
            if (status instanceof Governance) {
                state.governanceDetails = status;
            } else {
                state.governanceDetails = new Governance(status);
            }
        },
    },

    actions: {
        async getGovernance({ commit }, params) {
            const query = Governance.page(params.page || 1).orderBy(params.sort);

            if (params.search) {
                query.where('search', params.search);
            }

            const res = await query.params({ limit: 20, type: params.type }).get();

            commit('setGovernanceList', res.data);
            commit('setGovernanceListMeta', res.meta);
        },

        async getGovernanceDetails({ commit }, id) {
            const { data } = await this.$api.get(`/governance/${id}`);

            commit('setGovernanceDetails', data.data);
        },

        async storeGovernance({ commit }, formData) {
            let config = {
                header: {
                    'Content-Type': 'multipart/form-data',
                },
            };
            const { data } = await this.$api.post(`/governance`, formData, config);

            commit('setGovernanceDetails', data.data);
        },

        async updateGovernance({ commit }, formData) {
            const { data } = await this.$api.post(`/governance/${formData.id}`, formData.data);

            commit('setGovernanceDetails', data.data);
        },

        async deleteGovernance({ commit }, id) {
            await this.$api.delete(`/governance/${id}`);
        },
    },
};
