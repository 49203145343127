import '@/assets/scss/app.scss';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import { createApp } from 'vue';
import 'vue-toast-notification/dist/theme-bootstrap.css';
import App from './App.vue';
import router from './router';
import store from './store';

// import { createI18n } from 'vue-i18n';

//= =====================================================================================================================
// If you are a hero, you can tweak the above codes xD
// Best way to start our custom configurations below.

import globalComponents from './global-components';
import vue3GoogleLogin from 'vue3-google-login';

import VueSweetalert2 from 'vue-sweetalert2';
import Toast from 'vue-toast-notification';
// Models
import './models';

// css
import 'sweetalert2/dist/sweetalert2.min.css';
import 'vue3-easy-data-table/dist/style.css';

// services
import api from './services/api'; // an http requestor tool

// packages that should be global
import mitt from 'mitt';
import ACL from './utils/ACL';

// contants
import { ALL_PERMISSIONS } from '@/constants/ACLConstants';

// Survey
import { surveyPlugin } from 'survey-vue3-ui';

// Laravel Echo
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
window.Pusher = Pusher;

store.dispatch('auth/init');

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.VUE_APP_WEBSOCKETS_KEY,
    wsHost: process.env.VUE_APP_WEBSOCKETS_SERVER,
    cluster: process.env.VUE_APP_PUSHER_CLUSTER,
    wsPort: 6001,
    forceTLS: false,
    disableStats: true,
    authEndpoint: `${process.env.VUE_APP_API_URL}/broadcasting/auth`,
    auth: {
        headers: {
            Authorization: `Bearer ${store.state.auth.token.accessToken}`,
        },
    },
});

let app = createApp(App).use(router).use(store);
// .use(VueFbCustomerChat, {
//     page_id: process.env.VUE_APP_FACEBOOK_CHAT_PAGE_ID,
//     theme_color: '#333333', // theme color in HEX
//     locale: 'en_US', // default 'en_US'
// });

const Toastoptions = {
    // You can set your default options here for
};

app.use(Toast, Toastoptions);
app.use(VueSweetalert2);
app.use(surveyPlugin);
app.use(vue3GoogleLogin, {
    clientId: '215841514338-s9mintuamv61ncsavaie5qie75gvkons.apps.googleusercontent.com',
});

const emitter = mitt();
app.config.globalProperties.$emitter = emitter;
app.config.globalProperties.$api = api;
app.config.globalProperties.$user = store.state.auth.user;
app.config.globalProperties.$permissions = ALL_PERMISSIONS;
app.config.globalProperties.$acl = new ACL();
store.$api = api;

// this is not supposed to be like this
// adding global components in the app.
Object.keys(globalComponents).forEach((key) => app.component(key, globalComponents[key]));

app.mount('#app');
