import Model from './Model';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

export default class Calender extends Model {
    resource() {
        return 'holiday';
    }

    get holidayDate() {
        return dayjs.utc(this.date).local().format('MMM DD, YYYY');
    }
}
