<template>
    <div>
        <div class="d-flex justify-content-start align-items-center mb-3" style="gap: 20px" v-if="showFiltering">
            <div>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-filter"
                    viewBox="0 0 16 16"
                >
                    <path
                        d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"
                    />
                </svg>
            </div>

            <select
                class="form-select"
                aria-label="Filter select"
                name="filterBy"
                style="max-width: 10rem"
                v-model="filterBy"
            >
                <option v-for="filter in filterableColumns" :key="filter.value" :value="filter.value">
                    {{ filter.text }}
                </option>
            </select>

            <input
                type="text"
                class="form-control"
                style="max-width: 15rem"
                v-model="filter"
                v-if="filterBy !== null"
                :placeholder="'Search by ' + filterableColumns.filter((item) => item.value === filterBy)[0].text"
                @keyup="triggerFilter"
            />

            <div class="row" v-if="isDateRangeEnabled">
                <div class="col-md-6 d-flex align-items-center gap-3">
                    <label for="fromDate" class="mt-2">From: </label>
                    <input
                        id="fromDate"
                        type="date"
                        class="form-control"
                        v-model="filterDateFrom"
                        @change="triggerFilter"
                    />
                </div>
                <div class="col-md-6 d-flex align-items-center gap-3">
                    <label for="toDate" class="mt-2">To: </label>
                    <input
                        id="toDate"
                        type="date"
                        class="form-control"
                        v-model="filterDateTo"
                        @change="triggerFilter"
                    />
                </div>
            </div>
        </div>
        <slot name="title" :data="data[0]"></slot>

        <table id="dataTable" class="table table-bordered table-striped">
            <thead>
                <tr>
                    <th v-for="column in columns" :key="column.name">
                        <div class="d-flex justify-content-between align-items-center">
                            {{ column.title }}
                            <div v-show="column.orderable">
                                <span @click="sort(column.name)">
                                    <svg
                                        v-if="this.sortableColumns[column.name] === 'asc'"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        class="bi bi-sort-up"
                                        viewBox="0 0 16 16"
                                    >
                                        <path
                                            d="M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707V12.5zm3.5-9a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"
                                        />
                                    </svg>

                                    <svg
                                        v-else-if="this.sortableColumns[column.name] === 'desc'"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        class="bi bi-sort-up"
                                        viewBox="0 0 16 16"
                                    >
                                        <path
                                            d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"
                                        />
                                    </svg>
                                    <div v-else>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            class="bi bi-sort-up"
                                            viewBox="0 0 16 16"
                                        >
                                            <path
                                                d="M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707V12.5zm3.5-9a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"
                                            />

                                            <path
                                                d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"
                                            />
                                        </svg>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-if="isLoading">
                    <td colspan="100%" class="text-center">
                        <pulse-loader :loading="isLoading" color="#7366FF"></pulse-loader>
                    </td>
                </tr>
                <tr v-else v-for="item in data" :key="item">
                    <td v-for="column in columns" :key="column.name">
                        <slot :name="column.name" :row="item">
                            <div v-html="getValue(item, column)"></div>
                        </slot>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="mt-3 d-flex justify-content-between" v-if="rawResponse !== null">
            <div class="">
                <select
                    class="form-select"
                    name="perPage"
                    style="max-width: 5rem"
                    v-model="perPage"
                    @change="() => request()"
                >
                    <option v-for="number in perPageOptions" :key="number" :value="number">
                        {{ number }}
                    </option>
                </select>
                Showing {{ rawResponse.from }} to {{ rawResponse.to }} of {{ rawResponse.total }} entries
            </div>
            <ul class="pagination" style="gap: 4px">
                <li class="page-item" :class="{ disabled: rawResponse.current_page === 1 }">
                    <a class="page-link" @click="request(rawResponse.current_page - 1)">Previous</a>
                </li>
                <li
                    v-for="page in paginatedPages"
                    class="page-item"
                    :key="page"
                    :class="{ active: rawResponse.current_page === page }"
                >
                    <a class="page-link" @click="request(page)">{{ page }}</a>
                </li>
                <li class="page-item" :class="{ disabled: rawResponse.current_page === rawResponse.last_page }">
                    <a class="page-link" @click="request(rawResponse.current_page + 1)">Next</a>
                </li>
            </ul>
        </div>
    </div>
</template>
<style scoped>
.active > .page-link {
    background-color: #7366ff !important;
}
</style>

<script>
import _ from 'lodash';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';

export default {
    props: {
        columns: {
            type: Array,
            required: true,
        },

        isDateRangeEnabled: {
            type: Boolean,
            default: false,
        },

        model: { required: true },
    },

    components: {
        PulseLoader,
    },

    data() {
        return {
            data: [],
            filter: '',
            filterBy: null,
            sortableColumns: {},
            filterableColumns: [],
            rawResponse: null,
            isLoading: true,
            perPage: 10,
            currentPage: null,
            showFiltering: true,
            reactiveModel: this.model,
            filterDateFrom: null,
            filterDateTo: null,
        };
    },

    methods: {
        triggerFilter() {
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }
            this.timer = setTimeout(() => {
                this.request();
            }, 800);
        },

        sort(field) {
            // change values each clicked
            if (this.sortableColumns[field] === null) {
                this.sortableColumns[field] = 'asc';
            } else if (this.sortableColumns[field] === 'asc') {
                this.sortableColumns[field] = 'desc';
            } else {
                this.sortableColumns[field] = null;
            }

            this.request();
        },

        setSortableColumns() {
            let sortables = _.filter(this.columns, 'orderable');
            _.forEach(sortables, (item) => {
                this.sortableColumns[item.name] = null;
            });
        },

        setfilterableColumns() {
            let filterable = _.filter(this.columns, 'filterable');
            _.forEach(filterable, (item) => {
                let filterValue = item.name;

                if (filterValue.indexOf('.') !== -1) {
                    filterValue = filterValue.replace('.', '_');
                }
                this.filterableColumns.push({
                    text: item.title,
                    value: filterValue,
                });
            });

            if (this.filterableColumns.length > 0) {
                this.filterBy = this.filterableColumns[0].value;
            } else {
                this.showFiltering = false;
            }
        },

        refresh() {
            this.request(this.currentPage);
        },

        async request(page = null) {
            this.currentPage = page;

            this.isLoading = true;
            let requestModel = this.reactiveModel;

            requestModel.where(this.filterBy, this.filter);

            if (this.isDateRangeEnabled) {
                requestModel.where('from', this.filterDateFrom).where('to', this.filterDateTo);
            }

            let sorts = _.pickBy(this.sortableColumns); // get active sorts
            _.each(sorts, (value, key) => {
                let delimiter = '';
                if (value === 'desc') {
                    delimiter = '-';
                }

                requestModel.orderBy(`${delimiter}${key}`);
            });

            // page
            if (page) {
                requestModel.page(page);
            }

            this.rawResponse = await requestModel.limit(this.perPage).get();
            this.data = this.rawResponse.data;
            this.isLoading = false;
        },

        getValue(row, column) {
            let columnName = column.name;

            let result = row;
            if (columnName.indexOf('.') === -1) {
                result = row[columnName];
            } else {
                // if its trying to access an object
                const keys = columnName.split('.');
                for (const key of keys) {
                    if (!_.isEmpty(result) && result.hasOwnProperty(key)) {
                        result = result[key];
                    } else {
                        // If the property is not found, return null or undefined, or handle the error as needed
                        return 'n/a';
                    }
                }
            }

            // check if it has a mutate callback
            if (column.hasOwnProperty('mutate')) {
                result = column.mutate(result, row);
            }

            return result;
        },
    },

    computed: {
        perPageOptions() {
            return [5, 10, 15, 25, 50, 100];
        },

        paginatedPages() {
            const current = this.rawResponse.current_page;
            const last = this.rawResponse.last_page;
            const delta = 2; // 2 pages before and 2 pages after the current page
            const range = [];

            // Create a range of pages to display
            for (let i = Math.max(1, current - delta); i <= Math.min(last, current + delta); i++) {
                range.push(i);
            }

            // If the range doesn't include the first or last page, add ellipses
            if (range[0] > 1) {
                if (range[0] > 2) {
                    range.unshift('...');
                }
                range.unshift(1);
            }

            if (range[range.length - 1] < last) {
                if (range[range.length - 1] < last - 1) {
                    range.push('...');
                }
                range.push(last);
            }

            return range;
        },
    },

    watch: {
        model(value) {
            this.reactiveModel = value;
            this.request();
        },
    },

    created() {
        this.setSortableColumns();
        this.setfilterableColumns();
        this.request();
    },
};
</script>

<style></style>
