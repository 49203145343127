import SalaryGrade from '@/models/SalaryGrade';
import { each, find } from 'lodash';

export default {
    namespaced: true,
    state: {
        list: [],
        listMeta: {
            current_page: 0,
            last_page: 1,
        },
    },

    mutations: {
        setSalaryGradeList(state, salaryGrade) {
            each(salaryGrade, (status) => {
                const exist = find(state.list, { id: status.id });
                if (!exist) {
                    state.list.push(new SalaryGrade(status));
                }
            });
        },

        clearSalaryGradeList(state) {
            state.list = [];
        },

        clearSalaryGradeDetails(state) {
            state.salaryGradeDetails = null;
        },

        setSalaryGradeListMeta(state, meta) {
            state.listMeta = meta;
        },

        setSalaryGradeDetails(state, status) {
            if (status instanceof SalaryGrade) {
                state.salaryGradeDetails = status;
            } else {
                state.salaryGradeDetails = new SalaryGrade(status);
            }
        },
    },

    actions: {
        async getSalaryGrade({ commit }, params) {
            const query = SalaryGrade.page(params.page || 1).orderBy(params.sort);

            if (params.search) {
                query.where('search', params.search);
            }

            const res = await query.params({ type: params.type }).get();

            commit('setSalaryGradeList', res.data);
            commit('setSalaryGradeListMeta', res.meta);
        },

        async getSalaryGradeDetails({ commit }, id) {
            const { data } = await this.$api.get(`/salary-grade/${id}`);

            commit('setSalaryGradeDetails', data.data);
        },

        async storeSalaryGrade({ commit }, formData) {
            const { data } = await this.$api.post(`/salary-grade`, formData);

            commit('setSalaryGradeDetails', data.data);
        },

        async updateSalaryGrade({ commit }, formData) {
            const { data } = await this.$api.post(`/salary-grade/${formData.id}`, formData.data);

            commit('setSalaryGradeDetails', data.data);
        },

        async updateActiveSalaryGradeTranches({ commit }, id) {
            const { data } = await this.$api.post(`/update-active-salary-grade-tranch/${id}`);

            commit('setSalaryGradeDetails', data.data);
        },

        async deleteSalaryGrade({ commit }, id) {
            await this.$api.delete(`/salary-grade/${id}`);
        },
    },
};
