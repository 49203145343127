export default {
    path: '/auth',
    name: 'auth',
    redirect: { path: 'login' },
    meta: {
        requiresVisitor: true,
    },
    children: [
        {
            path: 'login',
            name: 'auth.login',
            component: () => import(/* webpackChunkName: "auth" */ '@/pages/Auth/LoginPage'),
        },
        {
            path: 'forgot-password',
            name: 'auth.forgot-password',
            component: () => import(/* webpackChunkName: "auth" */ '@/pages/Auth/ForgotPassword'),
        },
    ],
};
