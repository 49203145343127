import CoreCompetency from '@/models/CoreCompetency';
import { each, find } from 'lodash';

export default {
    namespaced: true,
    state: {
        list: [],
        listMeta: {
            current_page: 0,
            last_page: 1,
        },
    },

    mutations: {
        setCoreCompetencyList(state, coreCompetency) {
            each(coreCompetency, (status) => {
                const exist = find(state.list, { id: status.id });
                if (!exist) {
                    state.list.push(new CoreCompetency(status));
                }
            });
        },

        clearCoreCompetencyList(state) {
            state.list = [];
        },

        clearCoreCompetencyDetails(state) {
            state.coreCompetencyDetails = null;
        },

        setCoreCompetencyListMeta(state, meta) {
            state.listMeta = meta;
        },

        setCoreCompetencyDetails(state, status) {
            if (status instanceof CoreCompetency) {
                state.coreCompetencyDetails = status;
            } else {
                state.coreCompetencyDetails = new CoreCompetency(status);
            }
        },
    },

    actions: {
        async getCoreCompetency({ commit }, params) {
            const query = CoreCompetency.page(params.page || 1).orderBy(params.sort);

            if (params.search) {
                query.where('search', params.search);
            }

            const res = await query.params({ type: params.type }).get();

            commit('setCoreCompetencyList', res.data);
            commit('setCoreCompetencyListMeta', res.meta);
        },

        async getCoreCompetencyDetails({ commit }, id) {
            const { data } = await this.$api.get(`/core-competency/${id}`);

            commit('setCoreCompetencyDetails', data.data);
        },

        async storeCoreCompetency({ commit }, formData) {
            const { data } = await this.$api.post(`/core-competency`, formData);

            commit('setCoreCompetencyDetails', data.data);
        },

        async updateCoreCompetency({ commit }, formData) {
            const { data } = await this.$api.post(`/core-competency/${formData.id}`, formData.data);

            commit('setCoreCompetencyDetails', data.data);
        },

        async updateActiveCoreCompetencyTranches({ commit }, id) {
            const { data } = await this.$api.post(`/update-active-core-competency-tranch/${id}`);

            commit('setCoreCompetencyDetails', data.data);
        },

        async deleteCoreCompetency({ commit }, id) {
            await this.$api.delete(`/core-competency/${id}`);
        },
    },
};
