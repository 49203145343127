<template>
    <div>
        <button
            :class="buttonClass + ' d-flex align-items-center gap-2'"
            @click="showModal"
            type="button"
            v-if="showButton"
        >
            <div v-if="featherIcon !== ''" :type="featherIcon">
                <vue-feather size="15" :type="featherIcon" class="mr-3"></vue-feather>
            </div>
            <div>
                <span>{{ buttonLabel }}</span>
            </div>
        </button>
        <div class="modal" v-if="isModalVisible">
            <div class="modal-dialog" v-bind:class="customClass" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ title }}</h5>
                        <button class="btn-close" type="button" @click="closeModal"></button>
                    </div>
                    <div class="modal-body">
                        <slot></slot>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        showButton: {
            type: Boolean,
            default: true,
        },

        buttonClass: {
            type: String,
            default: 'btn btn-primary',
        },

        featherIcon: {
            type: String,
            default: '',
        },

        buttonLabel: {
            type: String,
            default: '',
        },

        title: {
            type: String,
            default: '',
        },

        customClass: {
            type: String,
            default: 'modal-md',
        },
    },

    data() {
        return {
            isModalVisible: false,
        };
    },

    methods: {
        closeModal() {
            this.$emit('onClose');
            this.isModalVisible = false;
        },

        showModal() {
            this.$emit('onShow');
            this.isModalVisible = true;
        },
    },
};
</script>
<style scoped>
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
}
</style>
