import Division from '@/models/Division';
import { each, find } from 'lodash';

export default {
    namespaced: true,
    state: {
        list: [],
        listMeta: {
            current_page: 0,
            last_page: 1,
        },
    },

    mutations: {
        setDivisionList(state, division) {
            each(division, (status) => {
                const exist = find(state.list, { id: status.id });
                if (!exist) {
                    state.list.push(new Division(status));
                }
            });
        },

        clearDivisionList(state) {
            state.list = [];
        },

        clearDivisionDetails(state) {
            state.divisionDetails = null;
        },

        setDivisionListMeta(state, meta) {
            state.listMeta = meta;
        },

        setDivisionDetails(state, status) {
            if (status instanceof Division) {
                state.divisionDetails = status;
            } else {
                state.divisionDetails = new Division(status);
            }
        },
    },

    actions: {
        async getDivision({ commit }, params) {
            const query = Division.page(params.page || 1).orderBy(params.sort);

            if (params.search) {
                query.where('search', params.search);
            }

            const res = await query.params({ limit: 20, type: params.type }).get();

            commit('setDivisionList', res.data);
            commit('setDivisionListMeta', res.meta);
        },

        async getDivisionDetails({ commit }, id) {
            const { data } = await this.$api.get(`/division/${id}`);

            commit('setDivisionDetails', data.data);
        },

        async storeDivision({ commit }, formData) {
            const { data } = await this.$api.post(`/division`, formData);
            commit('setDivisionDetails', data.data);
        },

        async updateDivision({ commit }, formData) {
            const { data } = await this.$api.post(`/division/${formData.id}`, formData.data);

            commit('setDivisionDetails', data.data);
        },

        async deleteDivision({ commit }, id) {
            await this.$api.delete(`/division/${id}`);
        },
    },
};
