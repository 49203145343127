import {
    CAN_VIEW_EMPLOYEE,
    CAN_VIEW_APPLICANT,
    CAN_VIEW_EMPLOYEE_SCHEDULE,
    CAN_VIEW_POSITION_ITEM,
    CAN_VIEW_PLANTILLA,
    CAN_VIEW_LOCALIZATION,
    CAN_VIEW_EMPLOYEE_STATUS,
    CAN_VIEW_EMPLOYMENT_STATUS,
    CAN_VIEW_ORGANIZATION_OFFICE,
    CAN_VIEW_ORGANIZATION_AGENCY,
    CAN_VIEW_BRANCH,
    CAN_VIEW_HOLIDAY,
    CAN_VIEW_SALARY_GRADE,
    CAN_VIEW_NATURE_OF_APPOINTMENT,
    CAN_VIEW_POSITION,
    CAN_VIEW_USER,
    CAN_VIEW_CONNECTION,
    CAN_VIEW_GOVERNANCE,
    CAN_VIEW_CORE_COMPETENCY,
    CAN_VIEW_LEARNING_AND_DEVELOPMENT,
    CAN_VIEW_TIMESHEET,
} from '@/constants/ACLConstants';

export const menuItems = {
    data: [
        {
            headTitle1: 'General',
            type: 'headtitle',
        },
        {
            title: 'Dashboard',
            icon: 'stroke-home',
            path: '/',
            type: 'link',
            badgeType: 'light-primary',
            active: false,
            permission: '',
        },
        {
            headTitle1: 'Modules',
            type: 'headtitle',
        },
        {
            title: 'Account',
            icon: 'stroke-charts',
            type: 'sub',
            active: false,
            permissions: [],
            children: [
                {
                    title: 'Profile',
                    path: '/profile',
                    type: 'link',
                    active: false,
                    permission: null,
                },
                {
                    title: 'Leave',
                    path: '/profile/leave',
                    type: 'link',
                    active: false,
                    permission: null,
                },
            ],
        },
        {
            title: 'Employees',
            icon: 'fill-user',
            path: '/employee',
            type: 'link',
            badgeType: 'light-secondary',
            active: false,
            permission: CAN_VIEW_EMPLOYEE,
        },
        {
            title: 'Applicant',
            icon: 'stroke-button',
            path: '/applicant',
            type: 'link',
            badgeType: 'light-secondary',
            active: false,
            permission: CAN_VIEW_APPLICANT,
        },
        // {
        //     title: 'Record Management',
        //     icon: 'stroke-file',
        //     iconf: 'fill-file',
        //     type: 'sub',
        //     active: false,
        //     children: [
        //         {
        //             path: '/ecommerce/product',
        //             title: 'Sex-Disaggregated Data',
        //             type: 'link',
        //             active: false,
        //         },
        //         {
        //             path: '/ecommerce/product/page/1',
        //             title: 'Documents Manager',
        //             type: 'link',
        //             active: false,
        //         },
        //     ],
        // },
        // {
        //     title: 'Talent Management',
        //     icon: 'stroke-email',
        //     iconf: 'fill-email',
        //     type: 'sub',
        //     active: false,
        //     children: [
        //         {
        //             path: '/email/readMail',
        //             title: 'Computerized Exam',
        //             type: 'link',
        //             active: false,
        //         },
        //         {
        //             path: '/email/compose',
        //             title: 'Computerized Competency-based Assessment',
        //             type: 'link',
        //             active: false,
        //         },
        //         {
        //             path: '/email/compose',
        //             title: 'Computerized Rating',
        //             type: 'link',
        //             active: false,
        //         },
        //         {
        //             path: '/email/compose',
        //             title: 'Job Position Management',
        //             type: 'link',
        //             active: false,
        //         },
        //         {
        //             path: '/email/compose',
        //             title: 'Online Job Posting Management',
        //             type: 'link',
        //             active: false,
        //         },
        //         {
        //             path: '/email/compose',
        //             title: 'Online Job Application Management',
        //             type: 'link',
        //             active: false,
        //         },
        //         {
        //             path: '/email/compose',
        //             title: 'Talent Acquisition Management',
        //             type: 'link',
        //             active: false,
        //         },
        //         {
        //             path: '/email/compose',
        //             title: 'Onboarding Monitoring and Tracking',
        //             type: 'link',
        //             active: false,
        //         },
        //         {
        //             path: '/email/compose',
        //             title: 'Engagement/Employment monitoring and Tracking',
        //             type: 'link',
        //             active: false,
        //         },
        //         {
        //             path: '/email/compose',
        //             title: 'Job Requirements Template',
        //             type: 'link',
        //             active: false,
        //         },
        //     ],
        // },
        // {
        //     title: 'Welfare Management',
        //     icon: 'stroke-chat',
        //     iconf: 'fill-chat',
        //     type: 'sub',
        //     active: false,
        //     children: [
        //         {
        //             path: '/app/chat',
        //             title: 'Welfare Monitoring',
        //             type: 'link',
        //             active: false,
        //         },
        //         {
        //             path: '/app/videochat',
        //             title: 'Counseling Management',
        //             type: 'link',
        //             active: false,
        //         },
        //         {
        //             path: '/app/videochat',
        //             title: 'Leave Applications',
        //             type: 'link',
        //             active: false,
        //         },
        //         {
        //             path: '/app/videochat',
        //             title: 'Leave Maintenance',
        //             type: 'link',
        //             active: false,
        //         },
        //         {
        //             path: '/app/videochat',
        //             title: 'Leave Monitoring and Forecast',
        //             type: 'link',
        //             active: false,
        //         },
        //         {
        //             path: '/app/videochat',
        //             title: 'Health and Wellness Tracking',
        //             type: 'link',
        //             active: false,
        //         },
        //         {
        //             path: '/app/videochat',
        //             title: 'Rewards and Recognition Management',
        //             type: 'link',
        //             active: false,
        //         },
        //     ],
        // },
        // {
        //     title: 'Financial Management',
        //     icon: 'stroke-user',
        //     iconf: 'fill-user',
        //     type: 'sub',
        //     active: false,
        //     children: [
        //         {
        //             path: '/users/profile',
        //             title: 'Salaries and Wages Management',
        //             type: 'link',
        //             active: false,
        //         },
        //         {
        //             path: '/users/edit',
        //             title: 'Benefits Management',
        //             type: 'link',
        //             active: false,
        //         },
        //         {
        //             path: '/users/cards',
        //             title: 'Deduction Management',
        //             type: 'link',
        //             active: false,
        //         },
        //         {
        //             path: '/users/cards',
        //             title: 'Government Share Management',
        //             type: 'link',
        //             active: false,
        //         },
        //         {
        //             path: '/users/cards',
        //             title: 'Payroll Management',
        //             type: 'link',
        //             active: false,
        //         },
        //     ],
        // },
        // {
        //     title: 'Attendance Management',
        //     icon: 'stroke-charts',
        //     iconf: 'fill-charts',
        //     type: 'sub',
        //     active: false,
        //     children: [
        //         {
        //             title: 'Attendance Monitoring',
        //             type: 'sub',
        //             active: false,
        //         },
        //         {
        //             title: 'Attendance Capturing',
        //             type: 'sub',
        //             active: false,
        //         },
        //         {
        //             title: 'Shift Management',
        //             type: 'sub',
        //             active: false,
        //         },
        //     ],
        // },
        // {
        //     title: 'Complaint and Feedback Management',
        //     icon: 'stroke-form',
        //     iconf: 'fill-form',
        //     type: 'sub',
        //     active: false,
        //     children: [
        //         {
        //             title: 'Complaints Monitoring',
        //             type: 'sub',
        //             active: false,
        //         },
        //         {
        //             title: 'Feedbacks Monitoring',
        //             type: 'sub',
        //             active: false,
        //         },
        //         {
        //             title: 'Feedbacks Management',
        //             type: 'sub',
        //             active: false,
        //         },
        //     ],
        // },
        // {
        //     title: 'Performance Mangement',
        //     icon: 'stroke-learning',
        //     iconf: 'fill-learning',
        //     type: 'sub',
        //     active: false,
        //     children: [
        //         {
        //             title: 'Computerized Assessment',
        //             type: 'sub',
        //             active: false,
        //         },
        //     ],
        // },
        // {
        //     title: 'Learning and Development Management',
        //     icon: 'stroke-form',
        //     iconf: 'fill-form',
        //     type: 'sub',
        //     active: false,
        //     children: [
        //         {
        //             title: 'Self, Peer and Supervisor Assessment',
        //             type: 'sub',
        //             active: false,
        //         },
        //         {
        //             title: 'Training Assessment',
        //             type: 'sub',
        //             active: false,
        //         },
        //         {
        //             title: 'Learning-Needs Analysis and Report',
        //             type: 'sub',
        //             active: false,
        //         },
        //         {
        //             title: 'IDP, OPCR, and IPCR Generation',
        //             type: 'sub',
        //             active: false,
        //         },
        //         {
        //             title: 'L and D Intervention Management',
        //             type: 'sub',
        //             active: false,
        //         },
        //     ],
        // },
        {
            title: 'Attendance',
            icon: 'stroke-charts',
            type: 'sub',
            active: false,
            permissions: [CAN_VIEW_EMPLOYEE_SCHEDULE, 'can_view_timesheet'],
            children: [
                {
                    title: 'Schedule Management',
                    path: '/employee-schedule',
                    type: 'link',
                    active: false,
                    permission: CAN_VIEW_EMPLOYEE_SCHEDULE,
                },
                {
                    title: 'Timesheet Management',
                    path: '/timesheet',
                    type: 'link',
                    active: false,
                    permission: CAN_VIEW_TIMESHEET, // can_view_timesheet this permission is not yet available,
                },
            ],
        },
        {
            title: 'Item Management',
            icon: 'stroke-starter-kit',
            type: 'sub',
            active: false,
            permissions: [CAN_VIEW_POSITION_ITEM, CAN_VIEW_PLANTILLA],
            children: [
                {
                    title: 'Items',
                    path: '/position-item',
                    type: 'link',
                    active: false,
                    permission: CAN_VIEW_POSITION_ITEM,
                },
                {
                    title: 'Plantilla',
                    path: '/plantilla',
                    type: 'link',
                    active: false,
                    permission: CAN_VIEW_PLANTILLA,
                },
            ],
        },
        {
            title: 'Talent Sourcing',
            icon: 'stroke-job-search',
            type: 'sub',
            active: false,
            permissions: [CAN_VIEW_CONNECTION, CAN_VIEW_CONNECTION],
            children: [
                {
                    title: 'Network',
                    icon: 'stroke-button',
                    path: '/talent-sourcing/network',
                    type: 'link',
                    badgeType: 'light-secondary',
                    active: false,
                    permission: CAN_VIEW_CONNECTION,
                },
                {
                    title: 'Applications',
                    icon: 'stroke-button',
                    path: '/talent-sourcing/application',
                    type: 'link',
                    badgeType: 'light-secondary',
                    active: false,
                    permission: CAN_VIEW_CONNECTION,
                },
            ],
        },
        {
            title: 'Onboarding',
            icon: 'stroke-icons',
            type: 'link',
            active: false,
            path: '/onboarding',
            permissions: [CAN_VIEW_LEARNING_AND_DEVELOPMENT],
            badgeType: 'light-secondary',
        },
        {
            title: 'L&D Planning and Monitoring',
            icon: 'stroke-search',
            type: 'link',
            active: false,
            path: '/ld-planning-monitoring',
            permissions: [CAN_VIEW_LEARNING_AND_DEVELOPMENT],
            badgeType: 'light-secondary',
        },
        // {
        //     title: 'Talent Selection and Placement',
        //     icon: 'stroke-search',
        //     type: 'sub',
        //     active: false,
        //     permissions: [CAN_VIEW_CONNECTION],
        //     children: [],
        // },
        {
            title: 'Team Management',
            icon: 'stroke-user',
            type: 'link',
            active: false,
            path: '/team-management',
            permissions: [CAN_VIEW_LEARNING_AND_DEVELOPMENT], // Todo: Team management
            badgeType: 'light-secondary',
        },
        {
            title: 'Blasting',
            icon: 'stroke-to-do',
            type: 'link',
            active: false,
            path: '/blasting',
            permissions: [CAN_VIEW_LEARNING_AND_DEVELOPMENT], // Todo: Team management
            badgeType: 'light-secondary',
        },
        {
            title: 'System Maintenance',
            icon: 'stroke-widget',
            iconf: 'fill-widget',
            type: 'sub',
            active: false,
            permissions: [
                CAN_VIEW_LOCALIZATION,
                CAN_VIEW_EMPLOYEE_STATUS,
                CAN_VIEW_EMPLOYMENT_STATUS,
                CAN_VIEW_ORGANIZATION_OFFICE,
                CAN_VIEW_ORGANIZATION_AGENCY,
                CAN_VIEW_BRANCH,
                CAN_VIEW_HOLIDAY,
                CAN_VIEW_SALARY_GRADE,
                CAN_VIEW_NATURE_OF_APPOINTMENT,
                CAN_VIEW_POSITION,
                CAN_VIEW_CORE_COMPETENCY,
            ],
            children: [
                {
                    title: 'Localization',
                    path: '/address',
                    type: 'link',
                    active: false,
                    permission: CAN_VIEW_LOCALIZATION,
                },
                {
                    title: 'Employment Status',
                    path: '/employment-status',
                    type: 'link',
                    active: false,
                    permission: CAN_VIEW_EMPLOYMENT_STATUS,
                },
                {
                    title: 'Employee Status',
                    path: '/employee-status',
                    type: 'link',
                    active: false,
                    permission: CAN_VIEW_EMPLOYEE_STATUS,
                },
                {
                    title: 'Organization',
                    type: 'sub',
                    active: false,
                    permissions: [CAN_VIEW_ORGANIZATION_OFFICE, CAN_VIEW_ORGANIZATION_AGENCY],
                    children: [
                        {
                            path: '/units',
                            title: 'Office',
                            type: 'link',
                            active: false,
                            permission: CAN_VIEW_ORGANIZATION_OFFICE,
                        },
                        {
                            path: '/agency',
                            title: 'Agency',
                            type: 'link',
                            active: false,
                            permission: CAN_VIEW_ORGANIZATION_AGENCY,
                        },
                    ],
                },
                {
                    title: 'Branches',
                    path: '/branches',
                    type: 'link',
                    active: false,
                    permission: CAN_VIEW_BRANCH,
                },
                {
                    title: 'Calendar',
                    path: '/calendar',
                    type: 'link',
                    active: false,
                    permission: CAN_VIEW_HOLIDAY,
                },
                {
                    title: 'Salary Grades',
                    path: '/salary-grade',
                    type: 'link',
                    active: false,
                    permission: CAN_VIEW_SALARY_GRADE,
                },
                {
                    title: 'Nature Of Appointment',
                    path: '/nature-of-appointment',
                    type: 'link',
                    active: false,
                    permission: CAN_VIEW_NATURE_OF_APPOINTMENT,
                },
                {
                    title: 'Positon',
                    path: '/position',
                    type: 'link',
                    active: false,
                    permission: CAN_VIEW_POSITION,
                },
                {
                    title: 'Competency',
                    path: '/core-competency',
                    type: 'link',
                    active: false,
                    permission: CAN_VIEW_CORE_COMPETENCY,
                },
                // {
                //     title: 'Document Categories',
                //     path: '/document-categories',
                //     type: 'link',
                //     active: false,
                // },
                // {
                //     title: 'Document Titles',
                //     path: '/document-titles',
                //     type: 'link',
                //     active: false,
                // },
            ],
        },
        {
            title: 'User and Security',
            icon: 'stroke-form',
            iconf: 'fill-form',
            type: 'sub',
            active: false,
            permissions: [CAN_VIEW_USER],
            children: [
                {
                    title: 'Users',
                    path: '/users',
                    type: 'link',
                    active: false,
                    permission: CAN_VIEW_USER,
                },
                {
                    title: 'Change History',
                    path: '/change-history',
                    type: 'link',
                    active: false,
                    permission: CAN_VIEW_USER,
                },
            ],
        },
        {
            headTitle1: 'Announcement',
            type: 'headtitle',
        },
        {
            title: 'Governance Modules',
            icon: 'stroke-learning',
            path: '/governance',
            type: 'link',
            badgeType: 'light-secondary',
            active: false,
            permission: CAN_VIEW_GOVERNANCE,
        },
        // {
        //     title: 'User Management',
        //     icon: 'stroke-ui-kits',
        //     iconf: 'fill-ui-kits',
        //     type: 'sub',
        //     active: false,
        //     children: [
        //         {
        //             title: 'User Accounts',
        //             type: 'sub',
        //             active: false,
        //         },
        //         {
        //             title: 'Audit Trail',
        //             type: 'sub',
        //             active: false,
        //         },
        //     ],
        // },

        // {
        //     headTitle1: 'Recover',
        //     headTitle2: 'All Neccesory Pages Added',
        //     type: 'headtitle',
        // },
        // {
        //     title: 'Backup and Restore',
        //     icon: 'stroke-ui-kits',
        //     iconf: 'fill-ui-kits',
        //     type: 'sub',
        //     active: false,
        //     children: [
        //         {
        //             title: 'Backup',
        //             type: 'sub',
        //             active: false,
        //         },
        //         {
        //             title: 'Restore',
        //             type: 'sub',
        //             active: false,
        //         },
        //     ],
        // },

        // {
        //     headTitle1: 'Others',
        //     headTitle2: 'All Neccesory Pages Added',
        //     type: 'headtitle',
        // },
        // {
        //     title: 'Extra',
        //     icon: 'stroke-widget',
        //     iconf: 'fill-widget',
        //     type: 'sub',
        //     active: false,
        //     children: [
        //         {
        //             title: 'Queuing / Appointment System',
        //             type: 'sub',
        //             active: false,
        //         },
        //         {
        //             title: 'Partner / Network Management',
        //             type: 'sub',
        //             active: false,
        //         },
        //         {
        //             title: 'Service Monitoring',
        //             type: 'sub',
        //             active: false,
        //         },
        //         {
        //             title: 'Certificates Request',
        //             type: 'sub',
        //             active: false,
        //         },
        //         {
        //             title: 'Generate Certificate',
        //             type: 'sub',
        //             active: false,
        //         },
        //         {
        //             title: 'Requisition Form',
        //             type: 'sub',
        //             active: false,
        //         },
        //     ],
        // },
        // // Not showing
        // {
        //     title: 'editor',
        //     icon: 'stroke-editors',
        //     iconf: 'fill-editors',
        //     type: 'sub',
        //     active: false,
        // },
        // {
        //     path: '/knowledgebase/knowledgebase',
        //     title: 'Knowledgebase',
        //     icon: 'stroke-knowledgebase',
        //     iconf: 'fill-knowledgebase',
        //     type: 'link',
        //     active: false,
        // },
        // {
        //     path: '/pages/support',
        //     title: 'Support Ticket',
        //     icon: 'stroke-support-tickets',
        //     iconf: 'fill-support-tickets',
        //     type: 'link',
        //     active: false,
        // },
    ],
};
