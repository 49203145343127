import axios from 'axios';
import env from './env';
import store from '@/store';

const api = axios.create({
    baseURL: env.apiUrl,
});

api.interceptors.request.use(
    function (config) {
        const accessToken = store.state.auth.token.accessToken;
        if (accessToken) {
            config.headers.Authorization = `Bearer ${accessToken}`;
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

api.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        return Promise.reject(error);
    }
);

export default api;
