import Model from './Model';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import _ from 'lodash'; // Import Lodash

dayjs.extend(utc);

export default class LearningAndDevelopment extends Model {
    resource() {
        return 'learning-and-development';
    }

    get totalApplicants() {
        let total = 0;

        if (this.learning_and_development) {
            total = _.keys(this.learning_and_development.applicants).length;
        }

        return total;
    }
}
