import _ from 'lodash';

export default {
    props: {
        name: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: false,
        },
        placeholder: {
            type: String,
            default: '',
        },
        required: {
            type: Boolean,
            default: false,
        },

        inputClass: {
            type: String,
            default: 'form-control',
        },

        rules: {
            type: Function,
            default: () => true,
        },

        responseError: {
            type: String,
            default: '',
        },

        modelValue: {
            default: null,
        },
    },

    data() {
        return {
            errorMessage: null,
        };
    },

    watch: {
        responseError(message) {
            this.setErrorMessage(message);
        },
    },

    methods: {
        runRule() {
            return this.rules(this.modelValue);
        },

        setErrorMessage(message) {
            if (message !== '' && message !== null) {
                this.errorMessage = message;
                this.isValidated = true;
            } else {
                this.errorMessage = null;
            }
        },
    },

    computed: {
        fieldId() {
            return `${this.name}${Math.random().toString(36).substring(2, 15)}`;
        },

        strClass() {
            let strClass = this.inputClass;
            if (this.errorMessage !== null && this.isValidated) {
                strClass = strClass + ' is-invalid';
            } else if (this.errorMessage === null && this.isValidated) {
                strClass = strClass + ' is-valid';
            }
            return strClass;
        },
    },

    mounted() {
        if (!_.isEmpty(this.responseError)) {
            this.setErrorMessage(this.responseError);
        }

        this.localValue = this.modelValue;
    },
};
