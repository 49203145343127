import Applicant from '@/models/Applicant';
import { each, find } from 'lodash';

export default {
    namespaced: true,
    state: {
        list: [],
        listMeta: {
            current_page: 0,
            last_page: 1,
        },
    },

    mutations: {
        setApplicantList(state, applicant) {
            each(applicant, (status) => {
                const exist = find(state.list, { id: status.id });
                if (!exist) {
                    state.list.push(new Applicant(status));
                }
            });
        },

        clearApplicantList(state) {
            state.list = [];
        },

        clearApplicantDetails(state) {
            state.applicantDetails = null;
        },

        setApplicantListMeta(state, meta) {
            state.listMeta = meta;
        },

        setApplicantDetails(state, status) {
            if (status instanceof Applicant) {
                state.applicantDetails = status;
            } else {
                state.applicantDetails = new Applicant(status);
            }
        },
    },

    actions: {
        async getApplicant({ commit }, params) {
            const query = Applicant.page(params.page || 1).orderBy(params.sort);

            if (params.search) {
                query.where('search', params.search);
            }

            const res = await query.params({ limit: 20, type: params.type }).get();

            commit('setApplicantList', res.data);
            commit('setApplicantListMeta', res.meta);
        },

        async getApplicantDetails({ commit }, id) {
            const { data } = await this.$api.get(`/applicant/${id}`);

            commit('setApplicantDetails', data.data);
        },

        async storeApplicant({ commit }, formData) {
            const { data } = await this.$api.post(`/applicant`, formData);

            commit('setApplicantDetails', data.data);
        },

        async updateApplicant({ commit }, formData) {
            const { data } = await this.$api.put(`/applicant/${formData.id}`, formData.data);

            commit('setApplicantDetails', data.data);
        },

        async deleteApplicant({ commit }, id) {
            await this.$api.delete(`/applicant/${id}`);
        },
    },
};
