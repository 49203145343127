<template>
    <div :id="fieldId">
        <div v-if="!isLabelEmpty(label)">
            <label class="fw-bold text-muted" :for="fieldId">{{ label }}</label>
            <span class="text-danger" v-if="required">*</span>
        </div>
        <slot name="action"></slot>

        <multiselect
            v-model="localValue"
            :placeholder="placeholder"
            :label="customLabel"
            :track-by="trackBy"
            :options="options"
            :multiple="multiple"
            open-direction="bottom"
            :disabled="disabled"
            :taggable="taggable"
            @tag="addTag"
            @select="handleChange"
            v-if="!isReadOnly"
        />
        <div v-else>
            <p>
                {{ displayValue(localValue) ?? 'n/a' }}
            </p>
        </div>
        <div class="text-danger" style="margin-top: 0.5rem" v-if="errorMessage">
            <vue-feather type="alert-octagon" size="1rem" />
            {{ errorMessage }}
        </div>
    </div>
</template>

<script>
'use strict';

import Multiselect from 'vue-multiselect';
import _ from 'lodash';
import 'vue-multiselect/dist/vue-multiselect.css';

export default {
    name: 'FormMultiSelect',

    inject: ['register', 'unRegisterField', 'isReadOnlyField'],

    components: {
        Multiselect,
    },

    props: {
        required: {
            type: Boolean,
            default: false,
        },

        label: {
            type: String,
            default: '',
        },

        placeholder: {
            type: String,
            default: '',
        },

        trackBy: {
            type: String,
            default: 'name',
        },

        multiple: {
            type: Boolean,
            default: true,
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        taggable: {
            type: Boolean,
            default: false,
        },

        options: {
            type: Array,
            default: () => [],
        },

        inputClass: {
            type: String,
            default: 'form-select',
        },

        name: {
            type: String,
            default: '',
        },

        customLabel: {
            type: String,
            default: 'name',
        },

        modelValue: {},

        responseError: {
            type: String,
            default: '',
        },

        isShowDefaultSelected: {
            type: Boolean,
            default: true,
        },

        isStandAlone: {
            type: Boolean,
            default: false,
        },

        index: {
            type: Number,
            default: 0,
        },
    },

    emits: ['update:modelValue'],

    watch: {
        localValue: {
            immediate: true,
            handler() {
                if (this.options && this.options.length > 0) {
                    const value = this.options.find((item) => item['id'] === this.localValue);

                    if (value) {
                        this.localValue = value;
                    }
                }
            },
        },

        modelValue(newValue) {
            this.localValue = newValue;
            if (this.localValue) {
                this.validate();
            }
        },

        responseError(value) {
            if (value) {
                this.errorMessage = value;
                this.changeStyle();
            }
        },
    },

    data() {
        return {
            localValue: this.modelValue,
            date: null,
            isValidated: false,
            errorMessage: null,
        };
    },

    methods: {
        isLabelEmpty(label) {
            let isEmpty = _.isEmpty(label);

            return isEmpty;
        },
        addTag(newTag) {
            this.options.push({ id: newTag, name: newTag });
            this.$emit('update:modelValue', newTag);
        },

        handleChange() {
            let value = [];

            if (this.localValue.value !== undefined) {
                value = this.localValue.value;
            } else if (this.localValue.id !== undefined) {
                value = this.localValue.id;
            } else if (this.localValue instanceof Object) {
                // TODO: Fetch if one data only
                _.each(this.localValue, (result, key) => {
                    if (result.full_name) {
                        value.push({ id: result.id, full_name: result.full_name });
                    } else {
                        value.push({ id: result.id, name: result.name });
                    }
                });
            }

            this.localValue['index'] = this.index;

            this.$emit('update:modelValue', value);
            this.$emit('onChange', this.localValue);
            this.validate();
        },

        changeStyle(borderColor = '#198754') {
            const parentElement = document.getElementById(this.fieldId);
            if (parentElement) {
                let descendantElement = parentElement.querySelector('.multiselect__tags');

                if (descendantElement) {
                    descendantElement.style.borderColor = borderColor;
                }
            }
        },

        validate() {
            this.isValidated = false;

            let result = this.basicValidation();

            if (result !== true) {
                this.errorMessage = result;
                this.changeStyle('#eb4034');
            } else {
                this.errorMessage = null;
                this.changeStyle();
            }

            this.isValidated = true;

            return result;
        },

        basicValidation() {
            if (this.required && (this.localValue === '' || this.localValue === null)) {
                return `${this.label} is required.`;
            }

            return true;
        },

        displayValue(value) {
            let display = 'n/a';

            if (value) {
                display = value.name;
            }

            return display;
        },
    },

    computed: {
        fieldId() {
            const fieldName = this.name.replace(/[^\w\s-]/g, '_');
            return `${fieldName}${Math.random().toString(36).substring(2, 15)}`;
        },

        isReadOnly() {
            let readOnly = false;

            if (typeof this.isReadOnlyField !== 'undefined') {
                readOnly = this.isReadOnlyField;
            }

            return readOnly;
        },
    },

    mounted() {
        if (this.register !== undefined) {
            this.register(this);
        }
    },

    unmounted() {
        if (this.unRegisterField !== undefined) {
            this.unRegisterField(this);
        }
    },
};
</script>
