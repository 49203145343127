export const CAN_VIEW_DASHBOARD = 'can_view_dashboard';

export const DASHBOARD_TAGS = [CAN_VIEW_DASHBOARD];

export const CAN_VIEW_USER = 'can_view_user';
export const CAN_ADD_USER = 'can_add_user';
export const CAN_UPDATE_USER = 'can_update_user';
export const CAN_DELETE_USER = 'can_delete_user';

export const USER_TAGS = [CAN_VIEW_USER, CAN_ADD_USER, CAN_UPDATE_USER, CAN_DELETE_USER];

export const CAN_VIEW_EMPLOYEE = 'can_view_employee';
export const CAN_ADD_EMPLOYEE = 'can_add_employee';
export const CAN_UPDATE_EMPLOYEE = 'can_update_employee';
export const CAN_DOWNLOAD_PDS = 'can_download_pds';

export const EMPLOYEE_TAGS = [CAN_VIEW_EMPLOYEE, CAN_ADD_EMPLOYEE, CAN_UPDATE_EMPLOYEE, CAN_DOWNLOAD_PDS];

export const EMPLOYEE_STATUS = [CAN_VIEW_EMPLOYEE, CAN_ADD_EMPLOYEE, CAN_UPDATE_EMPLOYEE, CAN_DOWNLOAD_PDS];

export const CAN_VIEW_APPLICANT = 'can_view_applicant';
export const CAN_UPDATE_APPLICANT = 'can_update_applicant';
export const CAN_ADD_APPLICANT = 'can_add_applicant';

export const APPLICANT_TAGS = [CAN_VIEW_APPLICANT, CAN_UPDATE_APPLICANT, CAN_ADD_APPLICANT];

// TODO: performance of work type
// I dont see it being used in front-end though

/** System maintenance */
export const CAN_VIEW_HOLIDAY = 'can_view_holiday';
export const CAN_ADD_HOLIDAY = 'can_add_holiday';
export const CAN_UPDATE_HOLIDAY = 'can_update_holiday';
export const CAN_DELETE_HOLIDAY = 'can_delete_holiday';

export const HOLIDAY_TAGS = [CAN_VIEW_HOLIDAY, CAN_ADD_HOLIDAY, CAN_UPDATE_HOLIDAY, CAN_DELETE_HOLIDAY];

export const CAN_VIEW_POSITION_ITEM = 'can_view_position_item';
export const CAN_ADD_POSITION_ITEM = 'can_add_position_item';
export const CAN_UPDATE_POSITION_ITEM = 'can_update_position_item';
export const CAN_DELETE_POSITION_ITEM = 'can_delete_position_item';

export const POSITION_ITEM_TAGS = [
    CAN_VIEW_POSITION_ITEM,
    CAN_ADD_POSITION_ITEM,
    CAN_UPDATE_POSITION_ITEM,
    CAN_DELETE_POSITION_ITEM,
];

export const CAN_VIEW_PLANTILLA = 'can_view_plantilla';
export const PLANTILLA_TAGS = [CAN_VIEW_PLANTILLA];

export const CAN_VIEW_TRANCH = 'can_view_tranch';
export const CAN_ADD_TRANCH = 'can_add_tranch';
export const CAN_UPDATE_TRANCH = 'can_update_tranch';
export const CAN_DELETE_TRANCH = 'can_delete_tranch';

export const TRANCH_TAGS = [CAN_VIEW_TRANCH, CAN_ADD_TRANCH, CAN_UPDATE_TRANCH, CAN_DELETE_TRANCH];

export const CAN_VIEW_LOCALIZATION = 'can_view_localization';

export const LOCALIZATION_TAGS = [CAN_VIEW_LOCALIZATION];

export const CAN_VIEW_EMPLOYEE_SCHEDULE = 'can_view_employee_schedule';
export const CAN_ADD_EMPLOYEE_SCHEDULE = 'can_add_employee_schedule';
export const CAN_UPDATE_EMPLOYEE_SCHEDULE = 'can_update_employee_schedule';
export const CAN_DELETE_EMPLOYEE_SCHEDULE = 'can_delete_employee_schedule';

export const EMPLOYEE_SCHEDULE_TAGS = [
    CAN_VIEW_EMPLOYEE_SCHEDULE,
    CAN_ADD_EMPLOYEE_SCHEDULE,
    CAN_UPDATE_EMPLOYEE_SCHEDULE,
    CAN_DELETE_EMPLOYEE_SCHEDULE,
];

export const CAN_VIEW_TIMESHEET = 'can_view_timesheet';
export const CAN_ADD_TIMESHEET = 'can_add_timesheet';
export const CAN_UPDATE_TIMESHEET = 'can_update_timesheet';
export const CAN_DELETE_TIMESHEET = 'can_delete_timesheet';

export const TIMESHEET_TAGS = [CAN_VIEW_TIMESHEET, CAN_ADD_TIMESHEET, CAN_UPDATE_TIMESHEET, CAN_DELETE_TIMESHEET];

export const CAN_VIEW_EMPLOYEE_STATUS = 'can_view_employee_status';
export const CAN_ADD_EMPLOYEE_STATUS = 'can_add_employee_status';
export const CAN_EDIT_EMPLOYEE_STATUS = 'can_edit_employee_status';
export const CAN_DELETE_EMPLOYEE_STATUS = 'can_delete_employee_status';

export const EMPLOYEE_STATUS_TAGS = [
    CAN_VIEW_EMPLOYEE_STATUS,
    CAN_ADD_EMPLOYEE_STATUS,
    CAN_EDIT_EMPLOYEE_STATUS,
    CAN_DELETE_EMPLOYEE_STATUS,
];

export const CAN_VIEW_EMPLOYMENT_STATUS = 'can_view_employment_status';
export const CAN_ADD_EMPLOYMENT_STATUS = 'can_add_employment_status';
export const CAN_UPDATE_EMPLOYMENT_STATUS = 'can_update_employment_status';
export const CAN_DELETE_EMPLOYMENT_STATUS = 'can_delete_employment_status';

export const EMPLOYMENT_STATUS_TAGS = [
    CAN_VIEW_EMPLOYMENT_STATUS,
    CAN_ADD_EMPLOYMENT_STATUS,
    CAN_UPDATE_EMPLOYMENT_STATUS,
    CAN_DELETE_EMPLOYMENT_STATUS,
];

export const CAN_VIEW_ORGANIZATION_OFFICE = 'can_view_organization_office';
export const CAN_ADD_ORGANIZATION_OFFICE = 'can_add_organization_office';
export const CAN_UPDATE_ORGANIZATION_OFFICE = 'can_update_organization_office';
export const CAN_DELETE_ORGANIZATION_OFFICE = 'can_delete_organization_office';

export const OFFICE_TAGS = [
    CAN_VIEW_ORGANIZATION_OFFICE,
    CAN_ADD_ORGANIZATION_OFFICE,
    CAN_UPDATE_ORGANIZATION_OFFICE,
    CAN_DELETE_ORGANIZATION_OFFICE,
];

export const CAN_VIEW_ORGANIZATION_AGENCY = 'can_view_organization_agency';
export const CAN_ADD_ORGANIZATION_AGENCY = 'can_add_organization_agency';
export const CAN_UPDATE_ORGANIZATION_AGENCY = 'can_update_organization_agency';
export const CAN_DELETE_ORGANIZATION_AGENCY = 'can_delete_organization_agency';

export const AGENCY_TAGS = [
    CAN_VIEW_ORGANIZATION_AGENCY,
    CAN_ADD_ORGANIZATION_AGENCY,
    CAN_UPDATE_ORGANIZATION_AGENCY,
    CAN_DELETE_ORGANIZATION_AGENCY,
];

export const CAN_VIEW_BRANCH = 'can_view_branch';
export const CAN_ADD_BRANCH = 'can_add_branch';
export const CAN_UPDATE_BRANCH = 'can_update_branch';
export const CAN_DELETE_BRANCH = 'can_delete_branch';

export const BRANCHES_TAGS = [CAN_VIEW_BRANCH, CAN_ADD_BRANCH, CAN_UPDATE_BRANCH, CAN_DELETE_BRANCH];

export const CAN_VIEW_SALARY_GRADE = 'can_view_salary_grade';
export const CAN_ADD_SALARY_GRADE = 'can_add_salary_grade';
export const CAN_UPDATE_SALARY_GRADE = 'can_update_salary_grade';
export const CAN_DELETE_SALARY_GRADE = 'can_delete_salary_grade';

export const SALARY_GRADE_TAGS = [CAN_VIEW_SALARY_GRADE, CAN_ADD_SALARY_GRADE, CAN_UPDATE_SALARY_GRADE];

export const CAN_VIEW_NATURE_OF_APPOINTMENT = 'can_view_nature_of_appointment';
export const CAN_ADD_NATURE_OF_APPOINTMENT = 'can_add_nature_of_appointment';
export const CAN_UPDATE_NATURE_OF_APPOINTMENT = 'can_update_nature_of_appointment';
export const CAN_DELETE_NATURE_OF_APPOINTMENT = 'can_delete_nature_of_appointment';

export const NATURE_OF_APPOINTMENT_TAGS = [
    CAN_VIEW_NATURE_OF_APPOINTMENT,
    CAN_ADD_NATURE_OF_APPOINTMENT,
    CAN_UPDATE_NATURE_OF_APPOINTMENT,
    CAN_DELETE_NATURE_OF_APPOINTMENT,
];

export const CAN_VIEW_POSITION = 'can_view_position';
export const CAN_ADD_POSITION = 'can_add_position';
export const CAN_UPDATE_POSITION = 'can_update_position';
export const CAN_DELETE_POSITION = 'can_delete_position';

export const POSITION_TAGS = [CAN_VIEW_POSITION, CAN_ADD_POSITION, CAN_UPDATE_POSITION, CAN_DELETE_POSITION];

export const CAN_VIEW_CONNECTION = 'can_view_connection';
export const CAN_ADD_CONNECTION = 'can_add_connection';
export const CAN_UPDATE_CONNECTION = 'can_update_connection';
export const CAN_DELETE_CONNECTION = 'can_delete_connection';

export const CONNECTION_TAGS = [CAN_VIEW_CONNECTION, CAN_ADD_CONNECTION, CAN_UPDATE_CONNECTION, CAN_DELETE_CONNECTION];
export const CAN_VIEW_GOVERNANCE = 'can_view_governance';
export const CAN_ADD_GOVERNANCE = 'can_add_governance';
export const CAN_UPDATE_GOVERNANCE = 'can_update_governance';
export const CAN_DELETE_GOVERNANCE = 'can_delete_governance';

export const GOVERNANCE_TAGS = [CAN_VIEW_GOVERNANCE, CAN_ADD_GOVERNANCE, CAN_UPDATE_GOVERNANCE, CAN_DELETE_GOVERNANCE];
export const CAN_VIEW_ENGAGEMENT_RECORD = 'can_view_engagment_record';
export const CAN_ADD_ENGAGEMENT_RECORD = 'can_add_engagement_record';
export const CAN_UPDATE_ENGAGEMENT_RECORD = 'can_update_engagement_record';
export const CAN_DELETE_ENGAGEMENT_RECORD = 'can_delete_engagement_record';

export const ENGAGEMENT_RECORDS_TAGS = [
    CAN_VIEW_ENGAGEMENT_RECORD,
    CAN_ADD_ENGAGEMENT_RECORD,
    CAN_UPDATE_ENGAGEMENT_RECORD,
    CAN_DELETE_ENGAGEMENT_RECORD,
];

export const CAN_VIEW_APPLICATION = 'can_view_application';
export const CAN_ADD_APPLICATION = 'can_add_application';
export const CAN_UPDATE_APPLICATION = 'can_update_application';
export const CAN_DELETE_APPLICATION = 'can_delete_application';

export const APPLICATION_TAGS = [
    CAN_VIEW_APPLICATION,
    CAN_ADD_APPLICATION,
    CAN_UPDATE_APPLICATION,
    CAN_DELETE_APPLICATION,
];

export const CAN_VIEW_CORE_COMPETENCY = 'can_view_core_competency';
export const CAN_ADD_CORE_COMPETENCY = 'can_add_core_competency';
export const CAN_UPDATE_CORE_COMPETENCY = 'can_update_core_competency';
export const CAN_DELETE_CORE_COMPETENCY = 'can_delete_core_competency';

export const CORE_COMPETENCY_TAGS = [
    CAN_VIEW_CORE_COMPETENCY,
    CAN_ADD_CORE_COMPETENCY,
    CAN_UPDATE_CORE_COMPETENCY,
    CAN_DELETE_CORE_COMPETENCY,
];

export const CAN_VIEW_LEARNING_AND_DEVELOPMENT = 'can_view_learning_and_development';
export const CAN_ADD_LEARNING_AND_DEVELOPMENT = 'can_add_learning_and_development';
export const CAN_UPDATE_LEARNING_AND_DEVELOPMENT = 'can_update_learning_and_development';
export const CAN_DELETE_LEARNING_AND_DEVELOPMENT = 'can_delete_learning_and_development';

export const LEARNING_AND_DEVELOPMENT_TAGS = [
    CAN_VIEW_LEARNING_AND_DEVELOPMENT,
    CAN_ADD_LEARNING_AND_DEVELOPMENT,
    CAN_UPDATE_LEARNING_AND_DEVELOPMENT,
    CAN_DELETE_LEARNING_AND_DEVELOPMENT,
];

// might need this
export const ALL_PERMISSIONS = [
    ...EMPLOYEE_TAGS,
    ...EMPLOYEE_STATUS_TAGS,
    ...APPLICANT_TAGS,
    ...HOLIDAY_TAGS,
    ...POSITION_ITEM_TAGS,
    ...TRANCH_TAGS,
    ...LOCALIZATION_TAGS,
    ...EMPLOYEE_SCHEDULE_TAGS,
    ...EMPLOYEE_STATUS_TAGS,
    ...EMPLOYMENT_STATUS_TAGS,
    ...OFFICE_TAGS,
    ...AGENCY_TAGS,
    ...BRANCHES_TAGS,
    ...SALARY_GRADE_TAGS,
    ...NATURE_OF_APPOINTMENT_TAGS,
    ...POSITION_TAGS,
    ...PLANTILLA_TAGS,
    ...USER_TAGS,
    ...CONNECTION_TAGS,
    ...GOVERNANCE_TAGS,
    ...DASHBOARD_TAGS,
    ...ENGAGEMENT_RECORDS_TAGS,
    ...APPLICATION_TAGS,
    ...CORE_COMPETENCY_TAGS,
    ...LEARNING_AND_DEVELOPMENT_TAGS,
    ...TIMESHEET_TAGS,
];
