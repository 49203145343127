'use strict';

import PHAdresses from '@/data/address';

const FIELDS = {
    barangay: null,
    municipal: null,
    province: null,
};

const ADDRESS_DELIMITER = ', ';

export default {
    props: {
        modelValue: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            field: { ...FIELDS },
            noBarangay: false,
            noRegion: false,
        };
    },

    watch: {
        modelValue(value) {
            if (value) {
                let [barangay, municipal, province] = value.split(ADDRESS_DELIMITER);
                this.field.barangay = barangay;
                this.field.municipal = municipal;
                this.field.province = province;
            }
        },

        generatedValue(value) {
            this.$emit('update:modelValue', value);
        },
    },

    methods: {
        getCountryData() {
            let region = Object.values(PHAdresses);

            return region;
        },
    },

    computed: {
        generatedValue() {
            return Object.values(this.field).join(ADDRESS_DELIMITER);
        },

        regionOptions() {
            let region = this.getCountryData();

            return Object.values(region).map((region) => region.region_name);
        },

        provinceOptions() {
            // TODO: make it simple. use array functions, perhaps lodash can do the tricks
            let country = this.getCountryData();
            let provinces = Object.values(country).map((province) => province.province_list);
            let provinceList = [];

            provinces.forEach((province) => {
                for (let provinceName in province) {
                    provinceList.push({
                        name: provinceName,
                        id: provinceName,
                    });
                }
            });

            return provinceList.sort();
        },

        municipalOptions() {
            // TODO: make it simple. use array functions, perhaps lodash can do the tricks
            let country = this.getCountryData();
            let municipalityList = [];
            let provinces = Object.values(country).map((province) => province.province_list);
            let selectedProvince = this.field.province;

            provinces.forEach((province) => {
                if (province[selectedProvince]) {
                    for (let municipalName in province[selectedProvince].municipality_list) {
                        municipalityList.push({
                            name: municipalName,
                            id: municipalName,
                        });
                    }
                }
            });

            return municipalityList;
        },

        barangayOptions() {
            // TODO: make it simple. use array functions, perhaps lodash can do the tricks
            let country = this.getCountryData();
            let barangayList = [];
            let provinces = Object.values(country).map((province) => province.province_list);
            let selectedMunicipality = this.field.municipal;
            let selectedProvince = this.field.province;

            provinces.forEach((province) => {
                if (province[selectedProvince]) {
                    let municipals = Object.values(province[selectedProvince]).map((municipal) => municipal);

                    municipals.forEach((municipal) => {
                        if (municipal[selectedMunicipality]) {
                            for (let i = 0; i < municipal[selectedMunicipality].barangay_list.length; i++) {
                                barangayList.push({
                                    name: municipal[selectedMunicipality].barangay_list[i],
                                    id: municipal[selectedMunicipality].barangay_list[i],
                                });
                            }
                        }
                    });
                }
            });

            return barangayList;
        },
    },

    mounted() {
        this.noRegion = true;
    },
};
