import Plantilla from '@/models/Plantilla';

export default {
    namespaced: true,

    mutations: {
        setPlantillaList(state, plantilla) {
            if (plantilla instanceof Plantilla) {
                state.list = plantilla;
            } else {
                state.list = new Plantilla(plantilla);
            }
        },

        setPlantillaTotal(state, total) {
            state.total = total;
        },

        setPlantillaListMeta(state, meta) {
            state.listMeta = meta;
        },
    },

    actions: {
        async getPlantilla({ commit }, departmentId) {
            const res = await Plantilla.params({ department_id: departmentId }).get();

            commit('setPlantillaList', res.data);
            commit('setPlantillaTotal', res.total);
        },
    },
};
