import Position from '@/models/Position';
import { each, find } from 'lodash';

export default {
    namespaced: true,
    state: {
        list: [],
        listMeta: {
            current_page: 0,
            last_page: 1,
        },
    },

    mutations: {
        setPositionList(state, position) {
            each(position, (status) => {
                const exist = find(state.list, { id: status.id });
                if (!exist) {
                    state.list.push(new Position(status));
                }
            });
        },

        clearPositionList(state) {
            state.list = [];
        },

        clearPositionDetails(state) {
            state.positionDetails = null;
        },

        setPositionListMeta(state, meta) {
            state.listMeta = meta;
        },

        setPositionDetails(state, status) {
            if (status instanceof Position) {
                state.positionDetails = status;
            } else {
                state.positionDetails = new Position(status);
            }
        },
    },

    actions: {
        async getPosition({ commit }, params) {
            const query = Position.page(params.page || 1).orderBy(params.sort);

            if (params.search) {
                query.where('search', params.search);
            }

            const res = await query.params({ limit: 20, type: params.type }).get();

            commit('setPositionList', res.data);
            commit('setPositionListMeta', res.meta);
        },

        async getPositionDetails({ commit }, id) {
            const { data } = await this.$api.get(`/position/${id}`);

            commit('setPositionDetails', data.data);
        },

        async storePosition({ commit }, formData) {
            const { data } = await this.$api.post(`/position`, formData);

            commit('setPositionDetails', data.data);
        },

        async updatePosition({ commit }, formData) {
            const { data } = await this.$api.post(`/position/${formData.id}`, formData.data);

            commit('setPositionDetails', data.data);
        },

        async deletePosition({ commit }, id) {
            await this.$api.delete(`/position/${id}`);
        },
    },
};
