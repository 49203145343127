<template>
    <div class="container">
        <div class="row">
            <div class="col-md-4">
                <text-field
                    class="search float-right"
                    ref="searchField"
                    background-color="grey lighten-3"
                    placeholder="Search Address"
                    v-model="searchValue"
                    @input="search"
                ></text-field>
            </div>
            <div class="col-md-3">
                <button class="btn btn-primary hris-search-button" type="button" @click="submitForm">
                    Search
                    <!-- <span>
                    <vue-feather type="loader" size="1rem" animation="spin" />
                    {{ formRefs.$busy ? 'Saving' : 'SUBMIT' }}
                </span> -->
                </button>
            </div>
        </div>
        <br />
        <easy-data-table
            ref="dataTable"
            :headers="headers"
            :items="items"
            :rows-per-page="10"
            table-class-name="customize-table"
            show-index
            border-cell
            :search-field="searchField"
            :search-value="searchValue"
            :hide-rows-per-page="true"
        >
            <template
                #pagination="{
                    prevPage,
                    nextPage,
                    isFirstPage,
                    isLastPage,
                    currentPaginationNumber,
                    maxPaginationNumber,
                }"
            >
                <nav aria-label="Page navigation example">
                    <ul class="pagination pagination-primary">
                        <li class="page-item">
                            <a
                                class="page-link"
                                href="javascript:void(0)"
                                aria-label="Previous"
                                :disabled="isFirstPage"
                                @click="prevPage"
                                ><span aria-hidden="true">Previous</span><span class="sr-only">Previous</span></a
                            >
                        </li>
                        <!-- <span
                                v-for="paginationNumber in maxPaginationNumber"
                                :key="paginationNumber"
                                class="customize-button"
                                :class="{
                                    active:
                                        paginationNumber ===
                                        currentPaginationNumber,
                                }"
                                @click="updatePage(paginationNumber)"
                            >
                                {{ paginationNumber }}
                            </span> -->
                        <li class="page-item">
                            <a
                                class="page-link"
                                href="javascript:void(0)"
                                aria-label="Next"
                                :disabled="isLastPage"
                                @click="nextPage"
                                ><span aria-hidden="true">Next</span><span class="sr-only">Next</span></a
                            >
                        </li>
                    </ul>
                </nav>
            </template>
        </easy-data-table>
    </div>
</template>
<style>
.hris-search-button {
    margin-top: 21px !important;
}

.action-wrapper .operation-icon {
    width: 20px;
    cursor: pointer;
}

.hris-action-button {
    margin-right: 10px;
}

.buttons-pagination {
    height: 27px;
}

.buttons-pagination .button {
    color: black;
}

.customize-table {
    --easy-table-row-border: 1px solid #f5f3f3;

    --easy-table-header-font-size: 14px;
    --easy-table-header-height: 50px;
    --easy-table-header-font-color: #000000;
    --easy-table-header-background-color: #ffffff;
    /*
    --easy-table-body-even-row-font-color: #fff;
    --easy-table-body-even-row-background-color: #c0c7d2; */

    --easy-table-body-row-font-color: #212529;
    /* --easy-table-body-row-background-color: #212529; */
    --easy-table-body-row-height: 50px;
    --easy-table-body-row-font-size: 14px;

    --easy-table-header-item-padding: 10px 15px;

    --easy-table-footer-font-color: #c0c7d2;
    --easy-table-footer-font-size: 14px;
    --easy-table-footer-padding: 0px 10px;
    --easy-table-footer-height: 50px;

    --easy-table-rows-per-page-selector-width: 70px;
    --easy-table-rows-per-page-selector-option-padding: 10px;
    --easy-table-rows-per-page-selector-z-index: 1;
}
</style>
<script>
'use strict';

export default {
    props: {
        headers: {
            type: Array,
            required: true,
        },

        items: {
            type: Array,
            required: true,
        },

        showIndex: {
            type: Boolean,
            default: false,
        },

        model: { required: true },
    },
};
</script>
