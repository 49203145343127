// Note: * Will strictly use this js-cookie just for the token*
// others should be stored in localStorage We Will install
// persistent vuex so that the values remains even the user refreshes the page.

import router from '@/router';

export default {
    namespaced: true,
    state: {
        user: {},
        userId: null,
        isAuthenticated: false,
        token: {
            accessToken: null,
            expiresIn: null,
            tokenType: 'Bearer',
            uuid: null,
        },
        timestamp: new Date().getTime(),
    },

    getters: {
        avatarThumb(state) {
            return state.user.avatar_thumb_url + '?timestamp=' + state.timestamp;
        },
    },

    mutations: {
        setUser(state, user) {
            if (user) {
                state.user = user;
                const strUser = JSON.stringify(user);
                localStorage.setItem('AUTH_USER', strUser);
            }
        },

        setToken(state, token) {
            if (token) {
                const strToken = JSON.stringify(token);
                localStorage.setItem('AUTH_TOKEN', strToken);

                state.token = token;

                state.isAuthenticated = true;
            }
        },

        clearToken(state) {
            localStorage.removeItem('AUTH_TOKEN');
            state.token = {
                accessToken: null,
                expiresIn: null,
                tokenType: 'Bearer',
                uuid: null,
            };
            state.isAuthenticated = false;
        },

        clearUser(state) {
            state.user = {};
            localStorage.removeItem('AUTH_USER');
        },

        setUserId(state, id) {
            state.userId = id;
        },

        changeTimestamp(state) {
            state.timestamp = new Date().getTime();
        },
    },

    actions: {
        init({ commit }) {
            const strToken = localStorage.getItem('AUTH_TOKEN');
            const strUser = localStorage.getItem('AUTH_USER');
            if (strToken) {
                const objToken = JSON.parse(strToken);
                commit('setToken', objToken);
            }

            if (strUser) {
                const objUser = JSON.parse(strUser);
                commit('setUserId', objUser.id);
                commit('setUser', objUser);
            }
        },

        getAuthenticatedUser({ commit }) {
            this.$api
                .get('me')
                .then(({ data }) => {
                    commit('setUser', data);
                })
                .catch((error) => {
                    if (error && error.response.status === 401) {
                        commit('clearToken');
                        commit('clearUser');
                        router.replace({ name: 'auth.login' });
                    }
                });
        },

        logout({ commit }) {
            this.$api.post('logout').then(() => {
                commit('clearToken');
                commit('clearUser');
                router.replace({ name: 'auth.login' });
            });
        },
    },
};
