import Agency from '@/models/Agency';
import { each, find } from 'lodash';

export default {
    namespaced: true,
    state: {
        list: [],
        listMeta: {
            current_page: 0,
            last_page: 1,
        },
    },

    mutations: {
        setAgencyList(state, agency) {
            each(agency, (status) => {
                const exist = find(state.list, { id: status.id });
                if (!exist) {
                    state.list.push(new Agency(status));
                }
            });
        },

        clearAgencyList(state) {
            state.list = [];
        },

        clearAgencyDetails(state) {
            state.agencyDetails = null;
        },

        setAgencyListMeta(state, meta) {
            state.listMeta = meta;
        },

        setAgencyDetails(state, status) {
            if (status instanceof Agency) {
                state.agencyDetails = status;
            } else {
                state.agencyDetails = new Agency(status);
            }
        },
    },

    actions: {
        async getAgency({ commit }, params) {
            const query = Agency.page(params.page || 1).orderBy(params.sort);

            if (params.search) {
                query.where('search', params.search);
            }

            const res = await query.params({ limit: 20, type: params.type }).get();

            commit('setAgencyList', res.data);
            commit('setAgencyListMeta', res.meta);
        },

        async getAgencyDetails({ commit }, id) {
            const { data } = await this.$api.get(`/agencies/${id}`);

            commit('setAgencyDetails', data.data);
        },

        async storeAgency({ commit }, formData) {
            const { data } = await this.$api.post(`/agencies`, formData);

            commit('setAgencyDetails', data.data);
        },

        async updateAgency({ commit }, formData) {
            const { data } = await this.$api.post(`/agencies/${formData.id}`, formData.data);

            commit('setAgencyDetails', data.data);
        },

        async deleteAgency({ commit }, id) {
            await this.$api.delete(`/agencies/${id}`);
        },
    },
};
