import NatureOfAppointment from '@/models/NatureOfAppointment';
import { each, find } from 'lodash';

export default {
    namespaced: true,
    state: {
        list: [],
        listMeta: {
            current_page: 0,
            last_page: 1,
        },
    },

    mutations: {
        setNatureOfAppointmentList(state, natureOfAppointment) {
            each(natureOfAppointment, (status) => {
                const exist = find(state.list, { id: status.id });
                if (!exist) {
                    state.list.push(new NatureOfAppointment(status));
                }
            });
        },

        clearNatureOfAppointmentList(state) {
            state.list = [];
        },

        clearNatureOfAppointmentDetails(state) {
            state.natureOfAppointmentDetails = null;
        },

        setNatureOfAppointmentListMeta(state, meta) {
            state.listMeta = meta;
        },

        setNatureOfAppointmentDetails(state, status) {
            if (status instanceof NatureOfAppointment) {
                state.natureOfAppointmentDetails = status;
            } else {
                state.natureOfAppointmentDetails = new NatureOfAppointment(status);
            }
        },
    },

    actions: {
        async getNatureOfAppointment({ commit }, params) {
            const query = NatureOfAppointment.page(params.page || 1).orderBy(params.sort);

            if (params.search) {
                query.where('search', params.search);
            }

            const res = await query.params({ limit: 20, type: params.type }).get();

            commit('setNatureOfAppointmentList', res.data);
            commit('setNatureOfAppointmentListMeta', res.meta);
        },

        async getNatureOfAppointmentDetails({ commit }, id) {
            const { data } = await this.$api.get(`/nature-of-appointment/${id}`);

            commit('setNatureOfAppointmentDetails', data.data);
        },

        async storeNatureOfAppointment({ commit }, formData) {
            const { data } = await this.$api.post(`/nature-of-appointment`, formData);

            commit('setNatureOfAppointmentDetails', data.data);
        },

        async updateNatureOfAppointment({ commit }, formData) {
            const { data } = await this.$api.post(`/nature-of-appointment/${formData.id}`, formData.data);

            commit('setNatureOfAppointmentDetails', data.data);
        },

        async deleteNatureOfAppointment({ commit }, id) {
            await this.$api.delete(`/nature-of-appointment/${id}`);
        },
    },
};
