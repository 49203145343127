import store from '@/store';
import { isEmpty } from 'lodash';
import { ALL_PERMISSIONS } from '@/constants/ACLConstants';

class ACL {
    /**
     * Checks if the user has permission
     * @param {String} permission
     * @returns
     */

    assertTagExist(tag) {
        const exist = ALL_PERMISSIONS.includes(tag);
        if (exist) {
            // goods
        } else {
            // console.warn(tag + ` is not declared in ACLConstants :)`);
        }
    }

    hasTag(permission) {
        if (!permission) {
            return false;
        }

        this.assertTagExist(permission);
        this.setUpdatedPermissions();
        let matched = this.positionPermissions.filter((item) => item.name === permission);

        if (isEmpty(matched)) {
            matched = this.userPermissions.filter((item) => item.name === permission);
        }

        return !isEmpty(matched);
    }

    /**
     * check if the use is having at least 1 matched permission in the given list of permissions
     * @param {Array} permissions
     * @returns Boolean
     */
    hasAnyTag(permissions) {
        if (!permissions) {
            return false;
        }
        permissions.forEach((permission) => {
            this.assertTagExist(permission);
        });

        this.setUpdatedPermissions();
        let matched = this.positionPermissions.filter((item) => {
            return permissions.includes(item.name);
        });

        if (isEmpty(matched)) {
            matched = this.userPermissions.filter((item) => permissions.includes(item.name));
        }

        return !isEmpty(matched);
    }

    setUpdatedPermissions() {
        this.user = store.state.auth.user;
        this.activeAppointment = null;
        this.positionPermissions = [];
        this.userPermissions = [];

        if (this.user.employee && !isEmpty(this.user)) {
            if (typeof this.user.employee.active_appointment !== 'undefined') {
                this.activeAppointment = this.user.employee.active_appointment;
                if (this.activeAppointment) {
                    this.positionPermissions = this.activeAppointment.position_item.position.permissions;
                }
            }

            if (typeof this.user.permissions !== 'undefined') {
                this.userPermissions = this.user.permissions;
            }
        }
    }
}

export default ACL;
