export default {
    namespaced: true,
    state: {
        list: [],
    },

    mutations: {
        setLookups(state, payload) {
            state.list = payload;
        },
    },

    actions: {
        async getLookups({ commit }, params) {
            const { data } = await this.$api.post(`/get-lookups`, {
                lookups: params,
            });

            commit('setLookups', data);
        },
    },
};
