<template>
    <div>
        <label class="mb-2 text-muted" :for="fieldId"
            ><strong>{{ label }} <span class="text-danger" v-if="required && !isReadOnly">*</span>:</strong></label
        >
        <div class="form-check mb-2" :id="fieldId" v-if="!isReadOnly">
            <div class="d-flex flex-wrap">
                <div v-for="(item, key) in options" :key="key" style="margin-right: 2rem">
                    <input
                        :id="getFieldId(fieldId, key)"
                        v-bind:class="strClass"
                        type="radio"
                        :value="item.value"
                        :name="name"
                        :checked="isChecked(item.value)"
                        @change="onValueChange"
                    />
                    <label class="form-check-label text-secondary" :for="getFieldId(fieldId, key)">
                        {{ item.text }}
                    </label>
                </div>
            </div>
        </div>
        <div class="mb-2" v-else>
            <p>
                {{ modelValue ?? 'not available' }}
            </p>
        </div>
    </div>
</template>

<script>
'use strict';

export default {
    name: 'Form2Radio',

    props: {
        label: {
            type: String,
            default: '',
        },

        name: {
            type: String,
            required: true,
        },

        required: {
            type: Boolean,
            default: false,
        },

        modelValue: {},

        options: {
            type: Object,
            default: () => {},
        },

        responseError: {
            type: String,
            default: '',
        },

        inputClass: {
            type: String,
            default: 'form-check-input',
        },
    },

    inject: ['register', 'unRegisterField', 'isReadOnlyField'],

    emits: ['onChangeChecked', 'update:modelValue'],

    watch: {
        modelValue(value) {
            if (value) {
                this.validate();
            }
        },
    },

    data() {
        return {
            errorMessage: null,
            isValidated: false,
            localValue: null,
        };
    },

    methods: {
        onValueChange(event) {
            this.$emit('update:modelValue', event.target.value);
            this.$emit('onChangeChecked', null);
            this.localValue = event.target.value;
        },

        validate() {
            this.isValidated = false;

            let result = this.basicValidation();

            if (result !== true) {
                this.errorMessage = result;
            } else {
                this.errorMessage = null;
            }

            this.isValidated = true;
            return result;
        },

        basicValidation() {
            if (this.required && (this.modelValue === '' || this.modelValue === null)) {
                return `${this.label} is required.`;
            }

            return true;
        },

        isChecked(value) {
            return this.modelValue === value;
        },

        getFieldId(fieldId, key) {
            return `${fieldId}-${key}`;
        },
    },

    computed: {
        strClass() {
            let strClass = this.inputClass;
            if (this.errorMessage !== null) {
                strClass = strClass + ' is-invalid';
            } else if (this.errorMessage === null && this.isValidated && this.modelValue !== null) {
                strClass = strClass + ' is-valid';
            }

            return strClass;
        },

        fieldId() {
            return `${this.name}${Math.random().toString(36).substring(2, 15)}`;
        },

        isReadOnly() {
            let readOnly = false;

            if (typeof this.isReadOnlyField !== 'undefined') {
                readOnly = this.isReadOnlyField;
            }

            return readOnly;
        },
    },

    mounted() {
        if (this.responseError !== '' && this.responseError !== null) {
            this.errorMessage = this.responseError;
        }

        this.localValue = this.modelValue;

        this.register(this);
    },

    unmounted() {
        this.unRegisterField(this);
    },
};
</script>
