import Section from '@/models/Section';
import { each, find } from 'lodash';

export default {
    namespaced: true,
    state: {
        list: [],
        listMeta: {
            current_page: 0,
            last_page: 1,
        },
    },

    mutations: {
        setSectionList(state, section) {
            each(section, (status) => {
                const exist = find(state.list, { id: status.id });
                if (!exist) {
                    state.list.push(new Section(status));
                }
            });
        },

        clearSectionList(state) {
            state.list = [];
        },

        clearSectionDetails(state) {
            state.sectionDetails = null;
        },

        setSectionListMeta(state, meta) {
            state.listMeta = meta;
        },

        setSectionDetails(state, status) {
            if (status instanceof Section) {
                state.sectionDetails = status;
            } else {
                state.sectionDetails = new Section(status);
            }
        },
    },

    actions: {
        async getSection({ commit }, params) {
            const query = Section.page(params.page || 1).orderBy(params.sort);

            if (params.search) {
                query.where('search', params.search);
            }

            const res = await query.params({ limit: 20, type: params.type }).get();

            commit('setSectionList', res.data);
            commit('setSectionListMeta', res.meta);
        },

        async getSectionDetails({ commit }, id) {
            const { data } = await this.$api.get(`/section/${id}`);

            commit('setSectionDetails', data.data);
        },

        async storeSection({ commit }, formData) {
            const { data } = await this.$api.post(`/section`, formData);

            commit('setSectionDetails', data.data);
        },

        async updateSection({ commit }, formData) {
            const { data } = await this.$api.post(`/section/${formData.id}`, formData.data);

            commit('setSectionDetails', data.data);
        },

        async deleteSection({ commit }, id) {
            await this.$api.delete(`/section/${id}`);
        },
    },
};
