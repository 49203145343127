<template>
    <div class="form-group">
        <slot name="label">
            <label :for="fieldId" v-html="label"></label>
            <span class="text-danger" v-if="required && !isReadOnly">*</span>
        </slot>
        <textarea
            :id="fieldId"
            :placeholder="placeholder"
            :rows="rows"
            :value="modelValue"
            @input="handleUpdate"
            @keyup="validate"
            class="form-control"
            :name="name"
            ref="textarea"
            v-bind:class="strClass"
            v-if="!isReadOnly"
        ></textarea>
        <div v-else>
            {{ modelValue }}
        </div>
        <div class="invalid-feedback text-danger" style="margin-top: 0.5rem" v-if="errorMessage !== null">
            <vue-feather type="alert-octagon" size="1rem" />
            {{ errorMessage }}
        </div>
    </div>
</template>
<script>
'use strict';

import FormFieldMixin from '@/components/Form2/mixins/FormFieldMixin';

export default {
    props: {
        rows: {
            type: Number,
            default: 3,
        },
    },

    mixins: [FormFieldMixin],

    inject: ['register', 'unRegisterField', 'isReadOnlyField'],

    name: 'Form2TextArea',

    data() {
        return {
            localValue: null,
            isValidated: false,
        };
    },

    methods: {
        handleUpdate(event) {
            this.$emit('update:modelValue', event.target.value);
            this.localValue = event.target.value;
        },

        validate() {
            this.isValidated = false;

            const result = this.basicValidation();

            this.runRule();

            if (result !== true) {
                this.errorMessage = result;
            } else {
                this.errorMessage = null;
            }

            this.isValidated = true;

            return result;
        },

        basicValidation() {
            if (this.required && (this.modelValue === '' || this.modelValue === null)) {
                return `${this.label} is required.`;
            }

            return true;
        },
    },

    computed: {
        isReadOnly() {
            let readOnly = false;

            if (typeof this.isReadOnlyField !== 'undefined') {
                readOnly = this.isReadOnlyField;
            }

            return readOnly;
        },
    },

    mounted() {
        this.register(this);
    },

    unmounted() {
        this.unRegisterField(this);
    },
};
</script>
