import Onboarding from '@/models/Onboarding';
import { each, find } from 'lodash';

export default {
    namespaced: true,
    state: {
        list: [],
        listMeta: {
            current_page: 0,
            last_page: 1,
        },
    },

    mutations: {
        setOnboardingList(state, onboarding) {
            each(onboarding, (status) => {
                const exist = find(state.list, { id: status.id });
                if (!exist) {
                    state.list.push(new Onboarding(status));
                }
            });
        },

        clearOnboardingList(state) {
            state.list = [];
        },

        clearOnboardingDetails(state) {
            state.onboardingDetails = null;
        },

        setOnboardingListMeta(state, meta) {
            state.listMeta = meta;
        },

        setOnboardingDetails(state, status) {
            if (status instanceof Onboarding) {
                state.onboardingDetails = status;
            } else {
                state.onboardingDetails = new Onboarding(status);
            }
        },
    },

    actions: {
        async getOnboarding({ commit }, params) {
            const query = Onboarding.page(params.page || 1).orderBy(params.sort);

            if (params.search) {
                query.where('search', params.search);
            }

            const res = await query.params({ limit: 20, type: params.type }).get();

            commit('setOnboardingList', res.data);
            commit('setOnboardingListMeta', res.meta);
        },

        async getOnboardingDetails({ commit }, id) {
            const { data } = await this.$api.get(`/onboarding/${id}`);

            commit('setOnboardingDetails', data.data);
        },

        async storeOnboarding({ commit }, formData) {
            const { data } = await this.$api.post(`/onboarding`, formData);
            commit('setOnboardingDetails', data.data);
        },

        async updateOnboarding({ commit }, formData) {
            const { data } = await this.$api.post(`/onboarding/${formData.id}`, formData.data);

            commit('setOnboardingDetails', data.data);
        },

        async deleteOnboarding({ commit }, id) {
            await this.$api.delete(`/onboarding/${id}`);
        },
    },
};
