<template>
    <div class="form-group">
        <label class="text-muted fw-bold" for="" v-html="label + ':'"></label>
        <span class="text-danger" v-if="required && !isReadOnly">*</span>
        <datepicker
            :id="fieldId"
            v-bind:class="strClass"
            v-model="date"
            :disabled="disabled"
            :format="format"
            :enable-time-picker="enableTimePicker"
            v-on:cleared="handleCleared"
            @closed="submitDate"
            v-if="!isReadOnly"
        >
        </datepicker>
        <div v-else>
            <p>
                {{ formattedDate(modelValue) }}
            </p>
        </div>
        <div class="text-danger" style="margin-top: 0.5rem" v-if="errorMessage !== null">
            <vue-feather type="alert-octagon" size="1rem" />
            <span style="margin-left: 0.5rem"> {{ errorMessage }}</span>
        </div>
    </div>
</template>
<style>
.dp__main {
    border: none !important;
    margin: -7px;
}

.hris-change-border {
    border-color: #dc3545 !important;
}
</style>
<script>
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import moment from 'moment';

export default {
    props: {
        name: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        required: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },

        format: {
            type: String,
            default: 'yyyy-MM-dd',
        },

        enableTimePicker: {
            type: Boolean,
            default: false,
        },

        inputClass: {
            type: String,
            default: 'datepicker-here form-control digits',
        },

        modelValue: {
            default: null,
        },

        responseError: {
            type: String,
            default: '',
        },
    },

    components: {
        Datepicker,
    },

    inject: ['register', 'unRegisterField', 'isReadOnlyField'],

    data() {
        return {
            localValue: null,
            date: null,
            errorMessage: null,
            isValidated: false,
            style: '',
        };
    },

    watch: {
        date(value) {
            this.$emit('update:modelValue', value);
            this.localValue = value;
        },

        modelValue(newValue) {
            this.date = newValue;
        },

        responseError(value) {
            this.errorMessage = value ?? '';
        },
    },

    methods: {
        formattedDate(value) {
            let formatted = 'n/a';
            if (value) {
                formatted = moment(value).format('LL');
            }

            return formatted;
        },

        validate() {
            this.isValidated = false;
            // migth create a mixin for date validation but for now, lets just stick to this

            let result = this.basicValidation();

            if (result !== true) {
                this.errorMessage = result;
            } else {
                this.errorMessage = null;
            }

            this.isValidated = true;

            return result;
        },

        basicValidation() {
            if (this.required && !this.date) {
                return `${this.label} is required`;
            }

            return true;
        },

        handleCleared() {
            this.validate();
        },

        submitDate() {
            this.validate();
            if (this.date instanceof Date) {
                this.date = this.date.toISOString().split('T')[0];
            }
        },
    },

    computed: {
        fieldId() {
            return `${this.name}${Math.random().toString(36).substring(2, 15)}`;
        },

        strClass() {
            let strClass = this.inputClass;

            let el = document.getElementsByClassName('dp__input')[0];

            if (el) {
                el.style.borderColor = '#ddd';
            }
            if (this.errorMessage !== null && this.isValidated) {
                strClass = strClass + ' is-invalid ';

                el.style.borderColor = '#dc3545';
            } else if (this.errorMessage === null && this.isValidated) {
                strClass = strClass + ' is-valid';
                el.style.borderColor = '#198754';
            }

            return strClass;
        },

        isReadOnly() {
            let readOnly = false;

            if (typeof this.isReadOnlyField !== 'undefined') {
                readOnly = this.isReadOnlyField;
            }

            return readOnly;
        },
    },

    mounted() {
        this.date = this.modelValue;

        this.register(this);
    },

    unmounted() {
        this.unRegisterField(this);
    },
};
</script>
