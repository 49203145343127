import EmployeeModel from '@/models/EmployeeModel';

export default {
    namespaced: true,

    actions: {
        async storeEmployee({ commit }, formData) {
            const { data } = await this.$api.post(`/employees`, formData);

            commit('setEmploymentStatusDetails', data.data);
        },

        async updateEmployee({ commit }, { data, id }) {
            let model = await EmployeeModel.find(id);
            model = Object.assign(model, data);
            await model.save();
        },

        async getEmployee({ commit }, id) {
            const model = await EmployeeModel.find(id);

            return model;
        },

        async deleteEmployee({ commit }, id) {
            await this.$api.delete(`/employees/${id}`);
        },
    },
};
