import Units from '@/models/Units';
import { each, find } from 'lodash';

export default {
    namespaced: true,
    state: {
        list: [],
        listMeta: {
            current_page: 0,
            last_page: 1,
        },
    },

    mutations: {
        setUnitList(state, units) {
            each(units, (status) => {
                const exist = find(state.list, { id: status.id });
                if (!exist) {
                    state.list.push(new Units(status));
                }
            });
        },

        clearUnitsList(state) {
            state.list = [];
        },

        clearUnitsDetails(state) {
            state.unitsDetails = null;
        },

        setUnitListMeta(state, meta) {
            state.listMeta = meta;
        },

        setUnitsDetails(state, status) {
            if (status instanceof Units) {
                state.unitDetails = status;
            } else {
                state.unitDetails = new Units(status);
            }
        },
    },

    actions: {
        async getUnits({ commit }, params) {
            const query = Units.page(params.page || 1).orderBy(params.sort);

            if (params.search) {
                query.where('search', params.search);
            }

            const res = await query.params({ limit: 20, type: params.type }).get();

            commit('setUnitList', res.data);
            commit('setUnitListMeta', res.meta);
        },

        async getUnitsDetails({ commit }, id) {
            const { data } = await this.$api.get(`/units/${id}`);

            commit('setUnitsDetails', data.data);
        },

        async storeUnits({ commit }, formData) {
            const { data } = await this.$api.post(`/units`, formData);

            commit('setUnitsDetails', data.data);
        },

        async updateUnits({ commit }, formData) {
            const { data } = await this.$api.post(`/units/${formData.id}`, formData.data);

            commit('setUnitsDetails', data.data);
        },

        async deleteUnits({ commit }, id) {
            await this.$api.delete(`/units/${id}`);
        },
    },
};
