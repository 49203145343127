import Model from './Model';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
export default class Applicant extends Model {
    resource() {
        return 'applicant';
    }

    get fullName() {
        return `${this.first_name} ${this.last_name}`;
    }

    get birthdayDate() {
        return dayjs.utc(this.birthday).local().format('MMM DD, YYYY');
    }
}
