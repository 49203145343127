<template>
    <div class="mt-2">
        <label class="d-block" for="chk-ani" id="checkbox-dark">
            <!-- TODO: Add Change or Input Validation -->
            <input
                class="checkbox-dark"
                type="checkbox"
                :placeholder="placeholder"
                :required="required"
                v-bind:class="customClass"
                v-model="localValue"
                :ref="customName"
                :name="customName"
                @input="formInput"
            />
            {{ label }}
        </label>

        <div class="invalid-feedback" v-if="errorMessage[customName]">
            {{ errorMessage[customName] }}
        </div>
    </div>
</template>
<style scoped>
.invalid-feedback {
    color: #dc3545 !important;
}
</style>

<script>
'use strict';

export default {
    name: 'CheckBox',

    model: {
        event: 'change',
    },

    props: {
        disabled: {
            type: Boolean,
            default: false,
        },

        customName: {
            type: String,
            default: '',
        },

        required: {
            type: Boolean,
            default: false,
        },

        label: {
            type: String,
            default: '',
        },

        placeholder: {
            type: String,
            default: '',
        },

        modelValue: {},
    },

    data() {
        return {
            localValue: false,
            input: false,
            errorMessage: [],
            onSubmit: false,
            customClass: '',
        };
    },

    created() {
        this.localValue = this.modelValue === 'true' || this.modelValue === true ? 1 : 0;
    },

    watch: {
        localValue(value) {
            if (value && value.length > 0) {
                this.customClass = 'is-valid';
            } else if (!value) {
                this.customClass = '';
            }

            this.input = value;
        },
    },

    methods: {
        formInput() {
            let value = this.input;
            let isActive = value === 'true' || value === true ? 1 : 0;
            this.$emit('update:modelValue', isActive);
        },
    },
};
</script>
