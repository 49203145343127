<template>
    <div class="hive-employee-form-fields-container w-100">
        <slot name="filter" />
        <div class="row">
            <div class="col-md-3" v-if="$slots.additionalAddress">
                <slot name="additionalAddress" />
            </div>
            <div class="col-md-4">
                <form-2-multi-select
                    class="mb-12"
                    label="Province"
                    :label-cols-lg="12"
                    :options="provinceOptions"
                    :multiple="false"
                    v-model="field.province"
                    :required="required"
                />
            </div>
            <div class="col-md-4">
                <form-2-multi-select
                    class="mb-12"
                    label="Municipal"
                    :label-cols-lg="12"
                    :options="municipalOptions"
                    :multiple="false"
                    v-model="field.municipal"
                    :required="required"
                />
            </div>
            <div class="col-md-4" v-if="!noBarangay">
                <form-2-multi-select
                    class="mb-12"
                    label="Barangay"
                    :label-cols-lg="12"
                    :options="barangayOptions"
                    :multiple="false"
                    v-model="field.barangay"
                    :required="required"
                />
            </div>
        </div>
    </div>
</template>

<script>
'use strict';

import FormAddressMixin from '@/components/form/FormAddressMixin';

export default {
    name: 'FormAddress',

    mixins: [FormAddressMixin],

    props: {
        required: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
