import PositionItem from '@/models/PositionItem';
import { each, find } from 'lodash';

export default {
    namespaced: true,
    state: {
        list: [],
        listMeta: {
            current_page: 0,
            last_page: 1,
        },
    },

    mutations: {
        setPositionItemList(state, positionItem) {
            each(positionItem, (status) => {
                const exist = find(state.list, { id: status.id });
                if (!exist) {
                    state.list.push(new PositionItem(status));
                }
            });
        },

        clearPositionItemList(state) {
            state.list = [];
        },

        clearPositionItemDetails(state) {
            state.positionItemDetails = null;
        },

        setPositionItemListMeta(state, meta) {
            state.listMeta = meta;
        },

        setPositionItemDetails(state, status) {
            if (status instanceof PositionItem) {
                state.positionItemDetails = status;
            } else {
                state.positionItemDetails = new PositionItem(status);
            }
        },
    },

    actions: {
        async getPositionItem({ commit }, params) {
            const query = PositionItem.page(params.page || 1).orderBy(params.sort);

            if (params.search) {
                query.where('search', params.search);
            }

            const res = await query.params({ limit: 20, type: params.type }).get();

            commit('setPositionItemList', res.data);
            commit('setPositionItemListMeta', res.meta);
        },

        async getPositionItemDetails({ commit }, id) {
            const { data } = await this.$api.get(`/position-item/${id}`);

            commit('setPositionItemDetails', data.data);
        },

        async storePositionItem({ commit }, formData) {
            const { data } = await this.$api.post(`/position-item`, formData);

            commit('setPositionItemDetails', data.data);
        },

        async updatePositionItem({ commit }, formData) {
            const { data } = await this.$api.put(`/position-item/${formData.id}`, formData.data);

            commit('setPositionItemDetails', data.data);
        },

        async deletePositionItem({ commit }, id) {
            await this.$api.delete(`/position-item/${id}`);
        },
    },
};
