<template>
    <form :class="formClass" @submit.prevent="submit">
        <slot></slot>
    </form>
</template>

<script>
'use strict';

export default {
    props: {
        formClass: {
            type: String,
            default: '',
        },

        form: {
            type: Object,
            required: true,
        },

        title: {
            type: String,
            default: '',
        },

        ref: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            inputFields: {},
            isSaving: false,
            onSubmit: false,
            disabled: false,
        };
    },
};
</script>
