<template>
    <div class="mt-2">
        <label class="form-label" for="validationTooltip03"
            >{{ label }}
            <span v-if="required" class="required-span"><strong>* </strong> </span>
        </label>
        <textarea
            class="form-control"
            :rows="rows"
            :placeholder="placeholder"
            v-model="localValue"
            :name="customName"
            :ref="customName"
            @input="formInput"
        >
        </textarea>
        <div class="invalid-feedback" v-if="errorMessage[customName]">
            {{ errorMessage[customName] }}
        </div>
    </div>
</template>
<style scoped>
.invalid-feedback {
    color: #dc3545 !important;
}
</style>

<script>
'use strict';

export default {
    name: 'TextArea',

    model: {
        event: 'change',
    },

    props: {
        disabled: {
            type: Boolean,
            default: false,
        },

        customName: {
            type: String,
            default: '',
        },

        required: {
            type: Boolean,
            default: false,
        },

        label: {
            type: String,
            default: '',
        },

        placeholder: {
            type: String,
            default: '',
        },

        value: {
            type: String,
        },

        modelValue: String,
    },

    data() {
        return {
            localValue: '',
            input: '',
            errorMessage: [],
            customClass: '',
            onSubmit: false,
        };
    },

    created() {
        this.localValue = this.modelValue;
    },

    watch: {
        localValue(value) {
            if (value && value.length > 0) {
                this.customClass = 'is-valid';
            } else if (!value) {
                this.customClass = '';
            }
            this.input = value;
        },
    },

    methods: {
        formInput() {
            this.$emit('update:modelValue', this.input);
        },
    },
};
</script>
