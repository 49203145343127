import {
    CAN_ADD_APPLICANT,
    CAN_ADD_BRANCH,
    CAN_ADD_CONNECTION,
    CAN_ADD_EMPLOYEE,
    CAN_ADD_EMPLOYEE_SCHEDULE,
    CAN_ADD_EMPLOYEE_STATUS,
    CAN_ADD_EMPLOYMENT_STATUS,
    CAN_ADD_GOVERNANCE,
    CAN_ADD_HOLIDAY,
    CAN_ADD_LEARNING_AND_DEVELOPMENT,
    CAN_ADD_NATURE_OF_APPOINTMENT,
    CAN_ADD_ORGANIZATION_AGENCY,
    CAN_ADD_ORGANIZATION_OFFICE,
    CAN_ADD_POSITION,
    CAN_ADD_POSITION_ITEM,
    CAN_ADD_SALARY_GRADE,
    CAN_ADD_CORE_COMPETENCY,
    CAN_VIEW_APPLICANT,
    CAN_VIEW_APPLICATION,
    CAN_VIEW_BRANCH,
    CAN_VIEW_CONNECTION,
    CAN_VIEW_EMPLOYEE,
    CAN_VIEW_EMPLOYEE_SCHEDULE,
    CAN_VIEW_EMPLOYEE_STATUS,
    CAN_VIEW_EMPLOYMENT_STATUS,
    CAN_VIEW_GOVERNANCE,
    CAN_VIEW_HOLIDAY,
    CAN_VIEW_LEARNING_AND_DEVELOPMENT,
    CAN_VIEW_LOCALIZATION,
    CAN_VIEW_NATURE_OF_APPOINTMENT,
    CAN_VIEW_ORGANIZATION_AGENCY,
    CAN_VIEW_ORGANIZATION_OFFICE,
    CAN_VIEW_PLANTILLA,
    CAN_VIEW_POSITION,
    CAN_VIEW_POSITION_ITEM,
    CAN_VIEW_SALARY_GRADE,
    CAN_VIEW_CORE_COMPETENCY,
    CAN_VIEW_USER,
    CAN_VIEW_TIMESHEET,
    CAN_ADD_USER,
} from '@/constants/ACLConstants';
import store from '@/store';
import ACL from '@/utils/ACL';
import { createRouter, createWebHistory } from 'vue-router';
import auth from './auth';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        auth,
        {
            path: '/two-authentication',
            name: 'two-authentication',
            meta: {
                requiresAuth: true,
            },
            component: () => import(/* webpackChunkName: "auth" */ '@/pages/Auth/TwoAuthentication'),
        },
        {
            path: '/',
            name: 'home',
            redirect: '/dashboard',
            component: () => import(/* webpackChunkName: "layouts" */ '@/pages/Modules'),
            meta: {
                requiresAuth: true,
            },
            children: [
                // Talent Sourcing
                {
                    path: 'talent-sourcing/network',
                    name: 'talent-sourcing.network',
                    component: () =>
                        import(
                            /* webpackChunkName: "talent-sourcing.network" */ '@/pages/Modules/TalentSourcing/Network/'
                        ),
                    meta: {
                        permission: CAN_VIEW_CONNECTION,
                    },
                },
                {
                    path: 'talent-sourcing/network/create',
                    name: 'talent-sourcing.network.create',
                    component: () =>
                        import(
                            /* webpackChunkName: "talent-sourcing.network.create" */ '@/pages/Modules/TalentSourcing/Network/create'
                        ),
                    meta: {
                        permission: CAN_ADD_CONNECTION,
                    },
                },
                {
                    path: 'talent-sourcing/network/:id',
                    name: 'talent-sourcing.network.details',
                    component: () =>
                        import(
                            /* webpackChunkName: "talent-sourcing.network.details" */ '@/pages/Modules/TalentSourcing/Network/details'
                        ),
                    meta: {
                        permission: CAN_VIEW_CONNECTION,
                    },
                },
                {
                    path: 'talent-sourcing/application',
                    name: 'talent-sourcing.application',
                    component: () =>
                        import(
                            /* webpackChunkName: "talent-sourcing.application" */ '@/pages/Modules/TalentSourcing/Application/'
                        ),
                    meta: {
                        permission: CAN_VIEW_APPLICATION,
                    },
                },
                {
                    path: 'talent-sourcing/applicant/:id/engagement',
                    name: 'talent-sourcing.applicant.engagement',
                    component: () =>
                        import(
                            /* webpackChunkName: "talent-sourcing.applicant.engagement" */ '@/pages/Modules/TalentSourcing/Engagement-messages/'
                        ),
                    meta: {
                        permission: CAN_VIEW_APPLICATION,
                    },
                },
                {
                    path: 'talent-sourcing/applicant/:id/engagement/:messageId/messages',
                    name: 'talent-sourcing.applicant.engagement.messages',
                    component: () =>
                        import(
                            /* webpackChunkName: "talent-sourcing.applicant.engagement.messages" */ '@/pages/Modules/TalentSourcing/Engagement-messages/messages'
                        ),
                    meta: {
                        permission: CAN_VIEW_APPLICATION,
                    },
                },
                {
                    path: '/dashboard',
                    name: 'dashboard',
                    component: () => import(/* webpackChunkName: "employee-status" */ '@/pages/Modules/Dashboard'),
                },
                // Administrative Maintenance Modules
                {
                    path: 'employee-status',
                    name: 'employee-status',
                    meta: {
                        permission: CAN_VIEW_EMPLOYEE,
                    },
                    component: () => import(/* webpackChunkName: "employee-status" */ '@/pages/Modules/EmployeeStatus'),
                },
                {
                    path: 'employee-status/create',
                    name: 'employee-status.create',
                    meta: {
                        permission: CAN_ADD_EMPLOYEE_STATUS,
                    },
                    component: () =>
                        import(/* webpackChunkName: "employee-status" */ '@/pages/Modules/EmployeeStatus/Create'),
                },
                {
                    path: 'employee-status/:id',
                    name: 'employee-status.details',
                    meta: {
                        permission: CAN_VIEW_EMPLOYEE_STATUS,
                    },
                    component: () =>
                        import(/* webpackChunkName: "employee-status" */ '@/pages/Modules/EmployeeStatus/Update'),
                },
                {
                    path: 'employment-status',
                    name: 'employment-status',
                    meta: {
                        permission: CAN_VIEW_EMPLOYMENT_STATUS,
                    },
                    component: () =>
                        import(/* webpackChunkName: "employment-status" */ '@/pages/Modules/EmploymentStatus'),
                },
                {
                    path: 'employment-status/create',
                    name: 'employment-status.create',
                    meta: {
                        permission: CAN_ADD_EMPLOYMENT_STATUS,
                    },
                    component: () =>
                        import(/* webpackChunkName: "employment-status" */ '@/pages/Modules/EmploymentStatus/Create'),
                },
                {
                    path: 'employment-status/:id',
                    name: 'employment-status.details',
                    meta: {
                        permission: CAN_VIEW_EMPLOYMENT_STATUS,
                    },
                    component: () =>
                        import(/* webpackChunkName: "employment-status" */ '@/pages/Modules/EmploymentStatus/Update'),
                },
                {
                    path: 'address',
                    name: 'address',
                    meta: {
                        permission: CAN_VIEW_LOCALIZATION,
                    },
                    component: () => import(/* webpackChunkName: "address" */ '@/pages/Modules/Address'),
                },
                // {
                //     path: 'department',
                //     name: 'department',
                //     component: () => import(/* webpackChunkName: "department" */ '@/pages/Modules/Department/'),
                // },
                // {
                //     path: 'department/:id',
                //     name: 'department.details',
                //     component: () => import(/* webpackChunkName: "department" */ '@/pages/Modules/Department/Update'),
                // },
                // {
                //     path: 'division',
                //     name: 'division',
                //     component: () => import(/* webpackChunkName: "division" */ '@/pages/Modules/Division'),
                // },
                // {
                //     path: 'section/:id',
                //     name: 'section.details',
                //     component: () => import(/* webpackChunkName: "section" */ '@/pages/Modules/Section/Update'),
                // },
                // {
                //     path: 'section',
                //     name: 'section',
                //     component: () => import(/* webpackChunkName: "section" */ '@/pages/Modules/Section'),
                // },
                // {
                //     path: 'division/:id',
                //     name: 'division.details',
                //     component: () => import(/* webpackChunkName: "division" */ '@/pages/Modules/Division/Update'),
                // },
                {
                    path: 'units',
                    name: 'units',
                    meta: {
                        permission: CAN_VIEW_ORGANIZATION_OFFICE,
                    },
                    component: () => import(/* webpackChunkName: "units" */ '@/pages/Modules/Units'),
                },
                {
                    path: 'units/create',
                    name: 'units.create',
                    meta: {
                        permission: CAN_ADD_ORGANIZATION_OFFICE,
                    },
                    component: () => import(/* webpackChunkName: "units" */ '@/pages/Modules/Units/Create'),
                },
                {
                    path: 'units/:id',
                    name: 'units.details',
                    meta: {
                        permission: CAN_VIEW_ORGANIZATION_OFFICE,
                    },
                    component: () => import(/* webpackChunkName: "units" */ '@/pages/Modules/Units/Update'),
                },
                {
                    path: 'agency',
                    name: 'agency',
                    meta: {
                        permission: CAN_VIEW_ORGANIZATION_AGENCY,
                    },
                    component: () => import(/* webpackChunkName: "agency" */ '@/pages/Modules/Agency'),
                },
                {
                    path: 'agency/create',
                    name: 'agency.create',
                    meta: {
                        permission: CAN_ADD_ORGANIZATION_AGENCY,
                    },
                    component: () => import(/* webpackChunkName: "agency" */ '@/pages/Modules/Agency/Create'),
                },
                {
                    path: 'agency/:id',
                    name: 'agency.details',
                    meta: {
                        permission: CAN_VIEW_ORGANIZATION_AGENCY,
                    },
                    component: () =>
                        import(/* webpackChunkName: "employment-status" */ '@/pages/Modules/Agency/Update'),
                },
                {
                    path: 'branches',
                    name: 'branches',
                    meta: {
                        permission: CAN_VIEW_BRANCH,
                    },
                    component: () => import(/* webpackChunkName: "branches" */ '@/pages/Modules/Branch'),
                },
                {
                    path: 'branches/create',
                    name: 'branches.create',
                    meta: {
                        permission: CAN_ADD_BRANCH,
                    },
                    component: () => import(/* webpackChunkName: "branches" */ '@/pages/Modules/Branch/Create'),
                },
                {
                    path: 'branches/:id',
                    name: 'branches.details',
                    meta: {
                        permission: CAN_VIEW_BRANCH,
                    },
                    component: () =>
                        import(/* webpackChunkName: "employment-status" */ '@/pages/Modules/Branch/Update'),
                },
                {
                    path: 'calendar',
                    name: 'calendar',
                    meta: {
                        permission: CAN_VIEW_HOLIDAY,
                    },
                    component: () => import(/* webpackChunkName: "calendar" */ '@/pages/Modules/Calendar'),
                },
                {
                    path: 'calendar/create',
                    name: 'calendar.create',
                    meta: {
                        permission: CAN_ADD_HOLIDAY,
                    },
                    component: () => import(/* webpackChunkName: "calendar" */ '@/pages/Modules/Calendar/Create'),
                },
                {
                    path: 'calendar/:id',
                    name: 'calendar.details',
                    meta: {
                        permission: CAN_VIEW_HOLIDAY,
                    },
                    component: () =>
                        import(/* webpackChunkName: "employment-status" */ '@/pages/Modules/Calendar/Update'),
                },
                {
                    path: 'salary-grade',
                    name: 'salary-grade',
                    meta: {
                        permission: CAN_VIEW_SALARY_GRADE,
                    },
                    component: () => import(/* webpackChunkName: "salary-grade" */ '@/pages/Modules/SalaryGrade'),
                },
                {
                    path: 'salary-grade/create',
                    name: 'salary-grade.create',
                    meta: {
                        permission: CAN_ADD_SALARY_GRADE,
                    },
                    component: () =>
                        import(/* webpackChunkName: "salary-grade" */ '@/pages/Modules/SalaryGrade/Create'),
                },
                {
                    path: 'salary-grade/:id',
                    name: 'salary-grade.details',
                    meta: {
                        permission: CAN_VIEW_SALARY_GRADE,
                    },
                    component: () =>
                        import(/* webpackChunkName: "salary-grade" */ '@/pages/Modules/SalaryGrade/Update'),
                },
                {
                    path: 'nature-of-appointment',
                    name: 'nature-of-appointment',
                    meta: {
                        permission: CAN_VIEW_NATURE_OF_APPOINTMENT,
                    },
                    component: () =>
                        import(/* webpackChunkName: "nature-of-appointment" */ '@/pages/Modules/NatureOfAppointment'),
                },
                {
                    path: 'nature-of-appointment/create',
                    name: 'nature-of-appointment.create',
                    meta: {
                        permission: CAN_ADD_NATURE_OF_APPOINTMENT,
                    },
                    component: () =>
                        import(
                            /* webpackChunkName: "nature-of-appointment" */ '@/pages/Modules/NatureOfAppointment/Create'
                        ),
                },
                {
                    path: 'nature-of-appointment/:id',
                    name: 'nature-of-appointment.details',
                    meta: {
                        permission: CAN_VIEW_NATURE_OF_APPOINTMENT,
                    },
                    component: () =>
                        import(
                            /* webpackChunkName: "nature-of-appointment" */ '@/pages/Modules/NatureOfAppointment/Update'
                        ),
                },
                {
                    path: 'position',
                    name: 'position',
                    meta: {
                        permission: CAN_VIEW_POSITION,
                    },
                    component: () => import(/* webpackChunkName: "positions" */ '@/pages/Modules/Position'),
                },
                {
                    path: 'position/create',
                    name: 'position.create',
                    meta: {
                        permission: CAN_ADD_POSITION,
                    },
                    component: () => import(/* webpackChunkName: "position" */ '@/pages/Modules/Position/Create'),
                },
                {
                    path: 'position/:id',
                    name: 'position.details',
                    meta: {
                        permission: CAN_VIEW_POSITION,
                    },
                    component: () => import(/* webpackChunkName: "position" */ '@/pages/Modules/Position/Update'),
                },
                {
                    path: 'document-categories',
                    name: 'document-categories',
                    component: () =>
                        import(/* webpackChunkName: "document-categories" */ '@/pages/Modules/DocumentCategories'),
                },
                {
                    path: 'document-titles',
                    name: 'document-titles',
                    component: () => import(/* webpackChunkName: "document-titles" */ '@/pages/Modules/DocumentTitles'),
                },
                // Employee Module
                {
                    path: 'employee',
                    name: 'employee',
                    component: () => import(/* webpackChunkName: "employee" */ '@/pages/Modules/Employee'),
                    meta: {
                        permission: CAN_VIEW_EMPLOYEE,
                    },
                },
                {
                    path: 'employee/create',
                    name: 'employee.create',
                    component: () =>
                        import(/* webpackChunkName: "employee-create" */ '@/pages/Modules/Employee/Create'),
                    meta: {
                        permission: CAN_ADD_EMPLOYEE,
                    },
                },
                {
                    path: 'employee/:id',
                    name: 'employee.details',
                    component: () =>
                        import(/* webpackChunkName: "employee-appointment" */ '@/pages/Modules/Employee/profile'),
                    meta: {
                        permission: CAN_VIEW_EMPLOYEE,
                    },
                },
                // Applicant Module
                {
                    path: 'applicant',
                    name: 'applicant',
                    component: () => import(/* webpackChunkName: "applicant" */ '@/pages/Modules/Applicant'),
                    meta: {
                        permission: CAN_VIEW_APPLICANT,
                    },
                },
                {
                    path: 'applicant/:id',
                    name: 'applicant.details',
                    component: () => import(/* webpackChunkName: "applicant" */ '@/pages/Modules/Applicant/Update'),
                    meta: {
                        permission: CAN_VIEW_APPLICANT,
                    },
                },
                {
                    path: 'applicant/:id/engagement',
                    name: 'applicant.engagement',
                    component: () =>
                        import(/* webpackChunkName: "applicant.engagement" */ '@/pages/Modules/Applicant/engagement'),
                    meta: {
                        permission: CAN_VIEW_APPLICANT,
                    },
                },
                {
                    path: 'applicant/create',
                    name: 'applicant.create',
                    component: () =>
                        import(/* webpackChunkName: "applicant-create" */ '@/pages/Modules/Applicant/Create'),
                    meta: {
                        permission: CAN_ADD_APPLICANT,
                    },
                },
                {
                    path: 'applicant/create-basic',
                    name: 'applicant.create-basic',
                    component: () =>
                        import(
                            /* webpackChunkName: "applicant-create-basic" */ '@/pages/Modules/Applicant/BasicApplicationForm'
                        ),
                    meta: {
                        permission: CAN_ADD_APPLICANT,
                    },
                },
                // Item Management Module
                {
                    path: 'position-item',
                    name: 'position-item',
                    component: () => import(/* webpackChunkName: "position-item" */ '@/pages/Modules/PositionItem'),
                    meta: {
                        permission: CAN_VIEW_POSITION_ITEM,
                    },
                },
                {
                    path: 'position-item/:id',
                    name: 'position-item.details',
                    meta: {
                        permission: CAN_VIEW_POSITION_ITEM,
                    },
                    component: () =>
                        import(/* webpackChunkName: "position-item" */ '@/pages/Modules/PositionItem/Update'),
                },
                {
                    path: 'position-item/create',
                    name: 'position-item.create',
                    meta: {
                        permission: CAN_ADD_POSITION_ITEM,
                    },
                    component: () =>
                        import(/* webpackChunkName: "position-item-create" */ '@/pages/Modules/PositionItem/Create'),
                },
                {
                    path: 'plantilla',
                    name: 'plantilla',
                    meta: {
                        permission: CAN_VIEW_PLANTILLA,
                    },
                    component: () => import(/* webpackChunkName: "plantilla" */ '@/pages/Modules/Plantilla'),
                },
                // Attendance
                {
                    path: 'employee-schedule',
                    name: 'employee-schedule',
                    meta: {
                        permission: CAN_VIEW_EMPLOYEE_SCHEDULE,
                    },
                    component: () =>
                        import(/* webpackChunkName: "employee-schedule" */ '@/pages/Modules/EmployeeSchedule'),
                },
                {
                    path: 'employee-schedule/:id',
                    name: 'employee-schedule.details',
                    meta: {
                        permission: CAN_VIEW_EMPLOYEE_SCHEDULE,
                    },
                    component: () =>
                        import(/* webpackChunkName: "employee-schedule" */ '@/pages/Modules/EmployeeSchedule/Update'),
                },
                {
                    path: 'employee-schedule/create',
                    name: 'employee-schedule.create',
                    meta: {
                        permission: CAN_ADD_EMPLOYEE_SCHEDULE,
                    },
                    component: () =>
                        import(
                            /* webpackChunkName: "employee-schedule-create" */ '@/pages/Modules/EmployeeSchedule/Create'
                        ),
                },
                {
                    path: 'timesheet',
                    name: 'timesheet',
                    meta: {
                        permission: CAN_VIEW_TIMESHEET,
                    },
                    component: () => import(/* webpackChunkName: "timesheet" */ '@/pages/Modules/Timesheet'),
                },
                {
                    path: 'users',
                    name: 'users',
                    component: () => import(/* webpackChunkName: "user-management" */ '@/pages/Modules/User'),
                    meta: {
                        permission: CAN_VIEW_USER,
                    },
                },
                {
                    path: 'change-history',
                    name: 'change-history',
                    component: () => import(/* webpackChunkName: "change-history" */ '@/pages/Modules/ChangeHistory'),
                    meta: {
                        permission: CAN_VIEW_USER,
                    },
                },
                {
                    path: 'user/create',
                    name: 'user.create',
                    component: () => import(/* webpackChunkName: "applicant" */ '@/pages/Modules/User/Create'),
                    meta: {
                        permission: CAN_ADD_USER,
                    },
                },

                {
                    path: 'user/:id',
                    name: 'user.details',
                    component: () => import(/* webpackChunkName: "applicant" */ '@/pages/Modules/User/Update'),
                    meta: {
                        permission: CAN_VIEW_USER,
                    },
                },
                {
                    path: 'governance',
                    name: 'governance',
                    component: () => import(/* webpackChunkName: "user-management" */ '@/pages/Modules/Governance'),
                },
                {
                    path: 'governance/create',
                    name: 'governance.create',
                    meta: {
                        permission: CAN_ADD_GOVERNANCE,
                    },
                    component: () => import(/* webpackChunkName: "governance" */ '@/pages/Modules/Governance/Create'),
                },
                {
                    path: 'governance/:id',
                    name: 'governance.details',
                    meta: {
                        permission: CAN_VIEW_GOVERNANCE,
                    },
                    component: () => import(/* webpackChunkName: "governance" */ '@/pages/Modules/Governance/Update'),
                },
                {
                    path: 'core-competency',
                    name: 'core-competency',
                    component: () => import(/* webpackChunkName: "core-competency" */ '@/pages/Modules/CoreCompetency'),
                },
                {
                    path: 'core-competency/create',
                    name: 'core-competency.create',
                    meta: {
                        permission: CAN_ADD_CORE_COMPETENCY,
                    },
                    component: () =>
                        import(/* webpackChunkName: "core-competency" */ '@/pages/Modules/CoreCompetency/Create'),
                },
                {
                    path: 'core-competency/:id',
                    name: 'core-competency.details',
                    meta: {
                        permission: CAN_VIEW_CORE_COMPETENCY,
                    },
                    component: () =>
                        import(/* webpackChunkName: "core-competency" */ '@/pages/Modules/CoreCompetency/Update'),
                },
                {
                    path: 'team-management/:teamId?',
                    name: 'team-management.index',
                    meta: {
                        permission: CAN_VIEW_CORE_COMPETENCY, // Todo: Team review
                    },
                    component: () => import(/* webpackChunkName: "team-management" */ '@/pages/Modules/Team'),
                },
                {
                    path: 'blasting',
                    name: 'blasting.index',
                    meta: {
                        permission: CAN_VIEW_USER, // Todo: need to add permissions for blasting
                    },
                    component: () => import(/* webpackChunkName: "team-management" */ '@/pages/Modules/Blasting'),
                },
                {
                    path: 'ld-planning-monitoring',
                    name: 'ld-planning-monitoring',
                    component: () =>
                        import(/* webpackChunkName: "user-management" */ '@/pages/Modules/LDPlaningMonitoring'),
                },
                {
                    path: 'ld-planning-monitoring/create',
                    name: 'ld-planning-monitoring.create',
                    meta: {
                        permission: CAN_ADD_LEARNING_AND_DEVELOPMENT,
                    },
                    component: () =>
                        import(
                            /* webpackChunkName: "ld-planning-monitoring" */ '@/pages/Modules/LDPlaningMonitoring/Create'
                        ),
                },
                {
                    path: 'ld-planning-monitoring/:id',
                    name: 'ld-planning-monitoring.details',
                    meta: {
                        permission: CAN_VIEW_LEARNING_AND_DEVELOPMENT,
                    },
                    component: () =>
                        import(
                            /* webpackChunkName: "ld-planning-monitoring" */ '@/pages/Modules/LDPlaningMonitoring/Update'
                        ),
                },
                {
                    path: 'onboarding',
                    name: 'onboarding',
                    component: () => import(/* webpackChunkName: "user-management" */ '@/pages/Modules/Onboarding'),
                },
                {
                    path: 'onboarding/:id',
                    name: 'onboarding.details',
                    meta: {
                        permission: CAN_VIEW_LEARNING_AND_DEVELOPMENT,
                    },
                    component: () => import(/* webpackChunkName: "onboarding" */ '@/pages/Modules/Onboarding/Update'),
                },

                // profile/account
                {
                    path: 'profile',
                    name: 'profile.index',
                    meta: {},
                    component: () => import(/* webpackChunkName: "profile" */ '@/pages/Modules/Profile/index'),
                },
                // profile/account
                {
                    path: 'profile/leave',
                    name: 'profile.leave',
                    meta: {},
                    component: () => import(/* webpackChunkName: "leave" */ '@/pages/Modules/Profile/Leave/index'),
                },
            ],
        },
        {
            path: '/access-denied',
            name: 'access-denied',
            component: () => import(/* webpackChunkName: "403" */ '@/pages/Forbidden'),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/:pathMatch(.*)',
            component: () => import(/* webpackChunkName: "404" */ '@/pages/NotFound'),
            meta: {
                requiresAuth: true,
            },
        },
    ],
});

router.beforeEach((to, from, next) => {
    const token = store.state.auth.token.accessToken;
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (!token) {
            return next({
                name: 'auth.login',
            });
        } else {
            // check required permission
            let hasPermission = true;

            if (to.meta.permission) {
                hasPermission = new ACL().hasTag(to.meta.permission);
            }

            if (!hasPermission) {
                return next({
                    name: 'access-denied',
                });
            }

            return next();
        }
    } else if (to.matched.some((record) => record.meta.requiresVisitor)) {
        if (token) {
            return next({
                name: 'dashboard',
            });
        } else {
            return next();
        }
    }
});

export default router;
