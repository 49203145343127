import { createStore } from 'vuex';

import agency from './modules/agency';
import applicant from './modules/applicant';
import auth from './modules/auth';
import branch from './modules/branch';
import calendar from './modules/calendar';
import department from './modules/department';
import division from './modules/division';
import employee from './modules/employee';
import employeeSchedule from './modules/employee-schedule';
import employeeStatus from './modules/employee-status';
import employmentStatus from './modules/employment-status';
import layout from './modules/layout';
import lookups from './modules/lookups';
import menu from './modules/menu';
import natureOfAppointment from './modules/nature-of-appointment';
import performanceOfWorkType from './modules/performance-of-work-type';
import position from './modules/position';
import positionItem from './modules/position-item';
import salaryGrade from './modules/salary-grade';
import section from './modules/section';
import units from './modules/units';
import governance from './modules/governance';
import coreCompetency from './modules/core-competency';
import learningAndDevelopment from './modules/learning-and-development';
import onboarding from './modules/onboarding';
import plantilla from './modules/plantilla';

export default createStore({
    modules: {
        auth,
        layout,
        menu,
        employee,
        employeeStatus,
        employmentStatus,
        units,
        department,
        division,
        lookups,
        agency,
        branch,
        calendar,
        salaryGrade,
        natureOfAppointment,
        position,
        applicant,
        section,
        performanceOfWorkType,
        positionItem,
        employeeSchedule,
        governance,
        coreCompetency,
        learningAndDevelopment,
        onboarding,
        plantilla,
    },
});
