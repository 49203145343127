'use strict';

import VueFeather from 'vue-feather';
import BreadCrumbs from '@/components/breadCrumbs';
import EasyDataTable from 'vue3-easy-data-table';

// Form
import TextField from '@/components/form/TextField';
import TextArea from '@/components/form/TextArea';
import FormGroup from '@/components/form/FormGroup';
import FormAddress from '@/components/form/FormAddress';
import CheckBox from '@/components/form/CheckBox';

// Form2
import Form2 from '@/components/Form2/Form2';
import Form2InputField from '@/components/Form2/Form2InputField';
import Form2Datepicker from '@/components/Form2/Form2Datepicker';
import Form2Radio from '@/components/Form2/Form2Radio';
import Form2MultiSelect from '@/components/Form2/Form2MultiSelect';
import Form2Select from '@/components/Form2/Form2Select';
import Form2TextArea from '@/components/Form2/Form2TextArea';
import Form2Checkbox from '@/components/Form2/Form2Checkbox';

// Datatable
import DataTable from '@/components/DataTable/DataTable';
import DataTablesCore from 'datatables.net-bs5';
import DataTableVue from 'datatables.net-vue3';
import { QuillEditor } from '@vueup/vue-quill';
// Modal
import Modal from '@/components/modal/Modal';
// TODO: Remove Datatable2
import DataTable2 from '@/components/DataTable/DataTable2';

// Generic Uploader
import GenericUploader from '@/components/GenericUploader/GenericUploader';

import { Tabs, Tab } from 'vue3-tabs-component';

DataTableVue.use(DataTablesCore);

const globalComponents = {
    QuillEditor,
    VueFeather,
    DataTable,
    BreadCrumbs,
    FormGroup,
    TextArea,
    TextField,
    CheckBox,
    EasyDataTable,
    Form2MultiSelect,
    Form2,
    Form2InputField,
    Form2Datepicker,
    Form2Radio,
    DataTable2,
    FormAddress,
    Form2TextArea,
    Form2Checkbox,
    Modal,
    Tabs,
    Tab,
    Form2Select,
    GenericUploader,
};

export default globalComponents;
